import { createSlice } from "@reduxjs/toolkit";
import { updateProfile } from "../thunks/profile";

const initialState = {
  editModal: false,
  loading: false,
  error: null,
  success: null,
};

const handlePending = (state, action) => {
  state.loading = true;
  state.error = initialState.error;
  state.success = initialState.success;
};
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = "Sorry! Something went wrong";
};
const onUpdateProfile = (state) => {
  state.loading = false;
  state.success = "Profile Updated Successfully";
};
// const handleUpdateProfileRejected = (state, ac)

const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setEditModal: (state, action) => {
      state.editModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProfile.pending, handlePending)
      .addCase(updateProfile.fulfilled, onUpdateProfile)
      .addCase(updateProfile.rejected, handleRejected);
  },
});

export const { setEditModal } = slice.actions;
export default slice.reducer;
