import React, { useEffect } from "react";

import HomeEvents from "../HomeEvents";

import { useDispatch, useSelector } from "react-redux";
import { getFavouriteEvents } from "../../../redux/thunks/events";
import useFirebaseUser from "../../../services/hooks/useFirebaseUser";

const FeaturedEventlist = ({
  events,
  addToFavHandler,
  removeToFavHandler,
  addToCalHandler,
}) => {
  const dispatch = useDispatch();
  const { favEvents } = useSelector((state) => state.events);
  const { user } = useFirebaseUser();

  useEffect(() => {
    dispatch(getFavouriteEvents(user));
  }, []);

  console.log("favEvents", favEvents);

  return (
    <div>
      {events?.length > 0 &&
        events.map((event, index) => {
          const isFind = user?.favorite?.find((fav) => fav == event.id);
          console.log(isFind);
          return (
            <>
              <h4 className="mb-3">Featured Event</h4>
              <HomeEvents
                event={event}
                key={index}
                isFav={isFind}
                addToFavHandler={addToFavHandler}
                removeToFavHandler={removeToFavHandler}
                addToCalHandler={addToCalHandler}
              />
            </>
          );
        })}
    </div>
  );
};

export default FeaturedEventlist;
