import React from "react";

import { getCreditCardPhoto } from "../../../pages/utility/helperFunctions";
import { deletePaymentCard } from "../../../redux/thunks/payment";

import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";

const PaymentCard = ({ card, active, setActive }) => {
  const dispatch = useDispatch();

  return (
    <div
      className={
        active?.no == card.id
          ? "payment-card-details mb-3 active-payment d-flex justify-content-between align-items-center "
          : "payment-card-details mb-3 d-flex justify-content-between align-items-center"
      }
      onClick={() => active && setActive({ no: card?.id, data: card })}
    >
      <div className="d-flex align-items-center gap-2">
        <div className="credit-card-img">
          <img src={getCreditCardPhoto(card?.card?.brand)} />
        </div>
        <div>
          <p>**** **** **** {card?.card.last4}</p>
          <p className="payment-expiry">
            Expires {`${card?.card.exp_month} / ${card?.card.exp_year}`}
          </p>
        </div>
      </div>
      <div>
        <MdDelete
          className="payment-delete-icon"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(deletePaymentCard(card?.id));
          }}
        />
      </div>
    </div>
  );
};

export default PaymentCard;
