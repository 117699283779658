import React, { useState, useRef } from "react";

import Error from "../../common/Error";
import InputField from "../../common/InputField";
import { setEditModal } from "../../../redux/slices/profileSlice";

import { Formik } from "formik";
import * as Yup from "yup";
import { FaEdit } from "react-icons/fa";
import { HiOutlineUserCircle } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { updateProfile } from "../../../redux/thunks/profile";
import { useNavigate } from "react-router-dom";
import useFirebaseUser from "../../../services/hooks/useFirebaseUser";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter your name"),
  mobileNumber: Yup.string().required("Please enter your mobile number"),
  //   email: Yup.string().required("Please enter your email"),
  //   password: Yup.string().required("Please enter your password"),
  address: Yup.string().required("Please enter your address"),
  //   dateOfBirth: Yup.string().required("Please enter your date of birth"),
  //   termsAndConditions: Yup.boolean()
  //     .required("The terms and conditions must be accepted.")
  //     .oneOf([true], "The terms and conditions must be accepted."),
  //   passwordRepeat: Yup.string()
  //     .required("Please repeat your password")
  //     .oneOf([Yup.ref("password"), null], "Passwords must match"),
  avatar: Yup.mixed().required("Profile Photo is required"),
});

const EditProfileModal = () => {
  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editModal } = useSelector((state) => state.profile);
  // const { user } = useSelector((state) => state.users);
  const { user } = useFirebaseUser();

  console.log(avatar);
  const fileRef = useRef(null);
  return (
    <Modal isOpen={editModal} toggle={() => dispatch(setEditModal(!editModal))}>
      <ModalHeader toggle={() => dispatch(setEditModal(!editModal))}>
        <p className="text-center">Update Profile</p>
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            name: user?.name,
            mobileNumber: user?.mobileNumber,
            address: user?.address,
            avatar: user?.avatar,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            values.avatar = avatar || user?.avatar;
            // console.log(values);
            dispatch(updateProfile({ data: values, navigate }));
            //   dispatch(signup({ navigate, data: values }));
            // console.log(values);
            // resetForm();
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            touched,
            errors,
          }) => {
            return (
              <div>
                <div>
                  {/* {avatar || user?.avatar ? ( */}
                  <div
                    className="upload-avatar  bg-white overflow-hidden mx-auto  "
                    onClick={() => {
                      fileRef.current.click();
                    }}
                  >
                    <img
                      className="avatar-img"
                      src={avatar ? URL.createObjectURL(avatar) : user.avatar}
                      alt=""
                    />
                    <FaEdit className="modal-edit-icon" />
                  </div>
                  {/* ) : ( */}
                  {/* <div
                      className="upload-avatar mx-auto mt-4 mb-1 "
                      onClick={() => {
                        fileRef.current.click();
                      }}
                    >
                      <HiOutlineUserCircle className="user-icon" />
                      <FaEdit className="edit-icon" />
                    </div> */}
                  {/* )} */}
                  {errors.avatar && touched.avatar && (
                    <Error text={errors.avatar} />
                  )}
                </div>
                <input
                  type="file"
                  ref={fileRef}
                  hidden
                  onChange={(e) => {
                    setFieldValue("avatar", e.target.files[0]);
                    setAvatar(e.target.files[0]);
                  }}
                />
                <InputField
                  placeholder="Name"
                  className="signup-inputs"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {errors.name && touched.name && <Error text={errors.name} />}
                <InputField
                  placeholder="Mobile Number"
                  className="signup-inputs"
                  type="text"
                  name="mobileNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mobileNumber}
                />
                {errors.mobileNumber && touched.mobileNumber && (
                  <Error text={errors.mobileNumber} />
                )}
                <InputField
                  placeholder="City"
                  className="signup-inputs"
                  type="text"
                  name="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                />
                {errors.address && touched.address && (
                  <Error text={errors.address} />
                )}
                <div className=" my-4 mx-auto text-center">
                  <button
                    type="submit"
                    className="forget-send-btn"
                    onClick={handleSubmit}
                  >
                    Send
                  </button>
                </div>
              </div>
            );
          }}
        </Formik>
      </ModalBody>
      {/* <ModalFooter>
        <button
          type="submit"
          className="forget-send-btn"
          // onClick={() => {
          //   forgetModal();
          // }}
        >
          Send
        </button>
        <Button
          color="secondary"
          onClick={() => dispatch(setEditModal(!editModal))}
        >
          Cancel
        </Button>
      </ModalFooter> */}
      {/* </form> */}
    </Modal>
  );
};

export default EditProfileModal;
