import React from "react";
import "./homeEvents.css";
import { RiHeartLine, RiHeartFill } from "react-icons/ri";
import { useNavigate, Link } from "react-router-dom";
import { getDateString } from "../../../pages/utility/getDateString";
import useFirebaseUser from "../../../services/hooks/useFirebaseUser";

const HomeEvents = ({ event, isFav, addToFavHandler, removeToFavHandler }) => {
  const navigate = useNavigate();
  const { user } = useFirebaseUser();
  return (
    <div className="home-event">
      <div className="home-event-img">
        <Link to={`/event-details/${event?.id}`} state={{ event }}>
          <img src={event.attachments} style={{ cursor: "pointer" }} />
        </Link>
      </div>
      <div className=" home-content d-flex gap-2 justify-content-between ">
        <div className="d-flex flex-column justify-content-between ">
          <div>
            <h5>{event?.title}</h5>
            <p>{event?.eventLocation}</p>
            <p>
              {getDateString(event?.eventDate)} {`At ${event?.startTime}`}
            </p>
          </div>

          <div className=" d-flex mt-4 mt-md-0 justify-content-md-end ">
            <Link to={`/event-details/${event?.id}`} state={{ event }}>
              <p
                className="see-more-detail"
                onClick={() => navigate(`/event-details/${event?.id}`)}
              >
                {" "}
                See More Details{" "}
              </p>
            </Link>
          </div>
        </div>
        <div className="home-event-actions">
          {user &&
            (isFav ? (
              <RiHeartFill
                className="fav-icon fav-red-icon "
                onClick={() => removeToFavHandler(event)}
              />
            ) : (
              <RiHeartLine
                className="fav-icon"
                onClick={() => addToFavHandler(event)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default HomeEvents;
