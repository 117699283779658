import React from "react";

import "../../home/HomeEvents/homeEvents.css";
import { getDateString } from "../../../pages/utility/getDateString";
import { useNavigate } from "react-router-dom";

const EventCards = ({ event }) => {
  const navigate = useNavigate();

  return (
    <div className="home-event">
      <div className="home-event-img">
        {/* <Link to={`/event-details/${event?.id}`} state={{ event }}> */}
        <img src={event?.attachments} style={{ cursor: "pointer" }} />
        {/* </Link> */}
      </div>
      <div className=" home-content d-flex gap-2 justify-content-between ">
        <div className="d-flex flex-column justify-content-between ">
          <div>
            <h5>{event?.title}</h5>
            <p>{event?.eventLocation}</p>
            <p>
              {getDateString(event?.eventDate)} {`At ${event?.startTime}`}
            </p>
          </div>

          <div className=" d-flex mt-4 mt-md-0 justify-content-md-end ">
            {/* <Link to={`/event-details/${event?.id}`} state={{ event }}> */}
            <p
              className="see-more-detail"
              onClick={() => navigate(`/event-details/${event?.id}`)}
            >
              See More Details{" "}
            </p>
            {/* </Link> */}
          </div>
        </div>
        {/* <div className="home-event-actions">
          {isFav ? (
            <RiHeartFill
              className="fav-icon fav-red-icon "
              onClick={() => removeToFavHandler(event)}
            />
          ) : (
            <RiHeartLine
              className="fav-icon"
              onClick={() => addToFavHandler(event)}
            />
          )}
        </div> */}
      </div>
    </div>
  );
};

export default EventCards;
