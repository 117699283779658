import { onSnapshot, doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { auth, db } from "../../config/firebaseConfig";
import { COLLECTIONS_NAMES } from "../../constants/firebase.constants";
import { onAuthStateChanged } from "firebase/auth";
import { setUser } from "../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useFirebaseUser = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const { USERS } = COLLECTIONS_NAMES;

  useEffect(() => {
    let unsubUser = undefined;
    const unsubAuth = onAuthStateChanged(auth, (user) => {
      console.log(user);
      if (!user) {
        setUser(null);
        setLoading(false);
      }
      unsubUser = onSnapshot(doc(db, USERS, user?.uid), (doc) => {
        console.log("Current data: ", doc.data());
        setUser(doc.data());
        // dispatch(setUser(doc.data()));
        // localStorage.setItem("authUser", JSON.stringify(doc.data()));
        setLoading(false);
      });
    });
    return () => {
      unsubAuth();
      if (unsubUser) {
        unsubUser();
      }
    };
  }, []);

  // if (user?.status != "active") {
  //   navigate("/login");
  // }

  return {
    user,
    loading,
  };
};

export default useFirebaseUser;
