import { createSlice } from "@reduxjs/toolkit";
import { getMyNotifications } from "../thunks/notifications";

const initialState = {
  notes: [],
  loading: false,
  error: null,
  success: null,
};

const handlePending = (state, action) => {
  state.loading = true;
  state.error = initialState.error;
  state.success = initialState.success;
};
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};
const onGetNotifications = (state, action) => {
  state.loading = false;
  state.notes = action.payload;
};

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyNotifications.pending, handlePending)
      .addCase(getMyNotifications.fulfilled, onGetNotifications)
      .addCase(getMyNotifications.rejected, handleRejected);
  },
});

export const {} = slice.actions;
export default slice.reducer;
