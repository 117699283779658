import { createAsyncThunk } from "@reduxjs/toolkit";
import firebaseService from "../../services/firebaseServices";
import { auth } from "../../config/firebaseConfig";
import { COLLECTIONS_NAMES } from "../../constants/firebase.constants.js";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";

const { EVENTS, TICKETS, USERS, CALLAPPCOL, MANAGERS } = COLLECTIONS_NAMES;

export const getEvents = createAsyncThunk(
  "events/getEvents",
  async (data, { rejectWithValue }) => {
    try {
      const events = await firebaseService.getDocuments(EVENTS);
      const filteredEvents = events.filter(
        (event, index) => {
          const dateString = event?.eventDate;
          const timeString = event?.startTime;

          // Combine the date and time strings into a single string
          const dateTimeString = dateString + " " + timeString;

          // Create a Date object from the combined string
          const inputDate = new Date(dateTimeString);

          // Get the current date and time
          const currentDate = new Date();
          console.log(
            event?.eventDate,
            event?.startTime,
            inputDate > currentDate
          );
          return inputDate > currentDate;
        }
        // new Date(event.eventDate) > new Date()
      );
      const sortedEvents = filteredEvents?.sort((a, b) => {
        const dateA = new Date(a.eventDate);
        const dateB = new Date(b.eventDate);
        return dateA - dateB;
      });
      console.warn(sortedEvents);
      return sortedEvents;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const getEventDetails = createAsyncThunk(
  "events/getEventDetails",
  async (payload, { rejectWithValue }) => {
    try {
      const { eventId, resolve } = payload;
      const response = await firebaseService.getDocumentById(EVENTS, eventId);
      console.log(response);
      if (typeof resolve == "function") {
        resolve(response);
      }
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const getEventsByIds = createAsyncThunk(
  "events,getEventsByIds",
  async (payload, { rejectWithValue }) => {
    try {
      console.log(payload);
      let events = [];
      const getEvents = await Promise.all(
        payload.map(async (eventId, index) => {
          const response = await firebaseService.getDocumentById(
            EVENTS,
            eventId
          );
          events.push(response);
        })
      );
      return events;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getPreviousEvents = createAsyncThunk(
  "events/getPreviousEvents",
  async (id, { rejectWithValue }) => {
    try {
      const previousEvents = await firebaseService.queryDocuments(
        TICKETS,
        "userId",
        id
      );
      const events = await Promise.all(
        previousEvents?.map(async (event) => {
          const e = await firebaseService.getDocumentById(
            EVENTS,
            event.eventId
          );
          return { eventData: e, ticketData: event };
        })
      );
      console.log(events);

      const filteredEvents = events.filter(
        (event) => new Date(event.eventData.eventDate) < new Date()
      );
      const sortedEvents = filteredEvents?.sort((a, b) => {
        const dateA = new Date(a.eventData.eventDate);
        const dateB = new Date(b.eventData.eventDate);
        return dateB - dateA;
      });
      console.warn(sortedEvents);
      return sortedEvents;

      // return filteredEvents;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const getUpcomingEvents = createAsyncThunk(
  "events/getUpcomingEvents",
  async (id, { rejectWithValue }) => {
    try {
      const upcomingEvents = await firebaseService.queryDocuments(
        TICKETS,
        "userId",
        id
      );
      const events = await Promise.all(
        upcomingEvents?.map(async (event) => {
          const e = await firebaseService.getDocumentById(
            EVENTS,
            event.eventId
          );
          return { eventData: e, ticketData: event };
        })
      );

      const filteredEvents = events.filter(
        (event) => new Date(event.eventData.eventDate) > new Date()
      );
      console.log("filteredEvents", filteredEvents);
      const sortedEvents = filteredEvents?.sort((a, b) => {
        const dateA = new Date(a.eventData.eventDate);
        const dateB = new Date(b.eventData.eventDate);
        return dateA - dateB;
      });
      console.warn(sortedEvents);
      return sortedEvents;
      // return filteredEvents;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const getFavouriteEvents = createAsyncThunk(
  "events/getFavouriteEvents",
  async (user, { rejectWithValue }) => {
    try {
      const favouriteEvents = user?.favorite;

      console.log(favouriteEvents);
      const events = await Promise.all(
        favouriteEvents?.map(async (eventId) => {
          try {
            const e = await firebaseService.getDocumentById(EVENTS, eventId);
            return e;
          } catch (err) {
            throw new Error(err.message);
          }
        })
      );
      console.log("eventsFav", events);
      const filteredEvents = events.filter(
        (event) => new Date(event.eventDate) > new Date()
      );
      console.log("filteredEvents", filteredEvents);
      const sortedEvents = filteredEvents?.sort((a, b) => {
        const dateA = new Date(a?.eventDate);
        const dateB = new Date(b?.eventDate);
        return dateA - dateB;
      });
      console.warn(sortedEvents);
      return sortedEvents;
      // return events;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const addToCalenderEvents = createAsyncThunk(
  "events/addToCalenderEvents",
  async (data, { rejectWithValue }) => {
    const { userId, event, navigate } = data;
    const eventData = {
      EndTime: event.eventDate,
      StartTime: event.eventDate,
      Subject: event.title,
      duration: event.requiredTime,
      eventId: event.id,
      eventName: event.title,
      imgUrl: event.attachments?.[0],
      startingTime: event.startTime,
    };
    console.log(data);
    try {
      const Data = await firebaseService.addDocumentWithNestedCollection(
        USERS,
        CALLAPPCOL,
        userId,
        eventData
      );
      navigate("/calender");
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const getCalenderEvents = createAsyncThunk(
  "events/getCalenderEvents",
  async (userId, { rejectWithValue }) => {
    try {
      const events = await firebaseService.getdocumentsWithNestedCollection(
        USERS,
        userId,
        CALLAPPCOL
      );
      console.log("events", events);
      const eventsData = await Promise.all(
        events?.map(async (event) => {
          try {
            const e = await firebaseService.getDocumentById(
              EVENTS,
              event.eventId
            );
            return { ...event, eventData: e };
          } catch (err) {
            throw new Error(err.message);
          }
        })
      );
      return eventsData;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const getEventManager = createAsyncThunk(
  "events/getEventManager",
  async (payload, { rejectWithValue }) => {
    try {
      console.log(payload);
      const { managersId } = payload;
      const response = await firebaseService.getDocumentById(
        MANAGERS,
        managersId
      );
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

// export const addFavouriteEvent = createAsyncThunk(
//   "users/addFavouriteEvent",
//   async (data, { rejectWithValue }) => {
//     const { user, eventId } = data;
//     try {
//       // var prevFavorite = [];
//       // if (user.favorite) {
//       //   prevFavorite = [...user.favorite];
//       // }
//       // const favoriteData = [...prevFavorite, eventId];
//       const curUser = await firebaseService.getDocumentById(
//         USERS,
//         user.currentUserId || user.uid
//       );
//       console.log(curUser);
//       const res = await firebaseService.updateDocument(
//         USERS,
//         user.currentUserId,
//         {
//           favorite: [eventId],
//         }
//       );
//       console.log("addtoFav", res);
//       // console.log(favoriteData);
//       // const newUserData = {
//       //   ...user,
//       //   favorite: favoriteData,
//       // };
//       // localStorage.setItem("authUser", JSON.stringify(newUserData));
//       // return newUserData;
//     } catch (err) {
//       console.log(err);
//       return rejectWithValue(err.message);
//     }
//   }
// );

export const removeFavouriteEvent = createAsyncThunk(
  "users/removeFavouriteEvent",
  async (data, { rejectWithValue, dispatch }) => {
    const { user, eventId } = data;
    try {
      const favoriteData = user.favorite.filter((fav) => fav !== eventId);

      await firebaseService.updateDocument(USERS, user.currentUserId, {
        favorite: favoriteData,
      });
      console.log(favoriteData);
      // const newUserData = {
      //   ...user,
      //   favorite: favoriteData,
      // };
      // localStorage.setItem("authUser", JSON.stringify(newUserData));
      // return newUserData;
      dispatch(openSuccessMessage("Removed from Favourites"));
    } catch (err) {
      console.log(err);
      dispatch(openErrorMessage("Something Went wrong"));
      return rejectWithValue(err.message);
    }
  }
);

export const addFavouriteEvent = createAsyncThunk(
  "events/addFavouriteEvent",
  async (data, { rejectWithValue, dispatch }) => {
    const { user, eventId } = data;
    try {
      console.log(data);
      // const curUser = await firebaseService.getDocumentById(
      //   USERS,
      //   user.currentUserId || user.uid
      // );
      // const curEvent = await firebaseService.getDocumentById(EVENTS, eventId);

      let favouriteEvents = [];
      // favouriteEvents = [...curUser.favorite, eventId];
      favouriteEvents = [...user.favorite, eventId];

      await firebaseService.updateDocument(USERS, user.currentUserId, {
        favorite: favouriteEvents,
      });
      dispatch(openSuccessMessage("Successfully! Added to Favourites."));
      return favouriteEvents;
    } catch (err) {
      console.log(err);
      dispatch(openErrorMessage("Sorry! Something went wrong"));
      return rejectWithValue(err.message);
    }
  }
);

// export const removeFavouriteEvent = createAsyncThunk(
//   "events/removeFavouriteEvent",
//   async (data, { rejectWithValue }) => {
//     const { user, favEvents, event } = data;
//     try {
//       console.log(data);
//       const favoriteData = favEvents?.filter((fav) => fav !== event);
//       console.log("favData", favoriteData);
//       firebaseService.updateDocument(USERS, user.currentUserId, {
//         favorite: favoriteData,
//       });
//       return favoriteData;
//     } catch (err) {
//       console.log(err);
//       return rejectWithValue(err.message);
//     }
//   }
// );

// export const getFavEventsDetails = () => {};
