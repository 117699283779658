import React from "react";
import "./bookingDetailsCard.css";
import Ticket from "../../../assets/images/ticket.png";

const BookingDetailsCard = ({ paymentForm, ticket }) => {
  return (
    <div className="booking-deatils-card">
      <div className="d-flex justify-content-between align-items-center ">
        <p className="mb-0">{ticket?.detail}</p>
        <img className="ticket" src={Ticket} alt="ticket" />
      </div>
      <div className="booking-ticket-price">
        <p className="symbol1">£</p>
        <p>{ticket?.price}/</p>
        <p className="symbol2">ticket</p>
      </div>
      <div className="booking-ticket-amount">
        <p>Tickets Amount: {ticket?.ticketsAmmount}</p>
      </div>
      <div
        className={
          paymentForm === 3 ? "booking-ticket-edit-3" : "booking-ticket-edit"
        }
      >
        {/* <div></div>
        <p className={paymentForm === 3 ? "d-none" : "d-block"}>Edit Details</p> */}
      </div>
    </div>
  );
};

export default BookingDetailsCard;
