import React, { useEffect } from "react";

import HomeEvents from "../HomeEvents";

import { useDispatch, useSelector } from "react-redux";
import { getFavouriteEvents } from "../../../redux/thunks/events";
import useFirebaseUser from "../../../services/hooks/useFirebaseUser";

const UpcomingEventList = ({
  events,
  heading,
  addToFavHandler,
  removeToFavHandler,
  addToCalHandler,
}) => {
  const dispatch = useDispatch();
  // const { user } = useSelector((state) => state.users);
  const { user } = useFirebaseUser();

  useEffect(() => {
    user && dispatch(getFavouriteEvents(user));
  }, [user]);

  console.log(user?.favorite);

  return (
    <div>
      {events?.length > 0 && (
        <>
          <h3 className="mb-4 ">{heading && "Upcoming Events"}</h3>
          <div>
            {events?.map((event, index) => {
              const isFind = user?.favorite?.find((fav) => {
                console.log(fav);
                console.log(event);
                return fav == event.id;
              });
              console.log("isFind", isFind);
              return (
                <>
                  <HomeEvents
                    event={event}
                    key={index}
                    isFav={isFind}
                    addToFavHandler={addToFavHandler}
                    removeToFavHandler={removeToFavHandler}
                    addToCalHandler={addToCalHandler}
                  />
                </>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default UpcomingEventList;
