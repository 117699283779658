import { createSlice } from "@reduxjs/toolkit";
import {
  login,
  signup,
  logout,
  getUser,
  // addFavouriteEvent,
  // removeFavouriteEvent,
  googleLogin,
  googleSignUp,
  forgetPassword,
  deleteAccount,
} from "../thunks/users";

const initialState = {
  user: null,
  loading: false,
  googleLoading: false,
  error: null,
  forgetPasswordError: null,
  success: null,
  isLogin: false,
  googleModal: false,
};

const handlePending = (state, action) => {
  state.loading = true;
  state.error = null;
  state.success = null;
  state.forgetPasswordError = null;
};
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};
const handleForgetPasswordRejected = (state, action) => {
  state.loading = false;
  state.forgetPasswordError = "Sorry, something went wrong...!";
};
const handleSuccess = (state, action) => {
  state.loading = false;
  state.success = action.payload;
};
const onSignUp = (state, action) => {
  // state.user = action.payload;
  // state.isLogin = true;
  state.loading = false;
};
const onLogin = (state, action) => {
  // state.user = action.payload;
  // state.isLogin = true;
  state.loading = false;
};
const onGoogleLogin = (state, action) => {
  state.user = action.payload;
  // state.isLogin = true;
  state.loading = false;
};
const handleGooglePending = (state, action) => {
  state.googleLoading = true;
  state.error = null;
};
const onGoogleSignUp = (state, action) => {
  // state.user = action.payload;
  state.googleLoading = false;
  // state.isLogin = true;
};
const onLogout = (state) => {
  // state.user = null;
  // state.isLogin = false;
  state.loading = false;
  state.googleModal = false;
};
const onForgetpassword = (state, action) => {
  state.loading = false;
  state.success = action.payload;
};
const onGetUser = (state, action) => {
  // state.user = action.payload;
  // state.isLogin = true;
  state.loading = false;
};
const onDeleteAccount = (state) => {
  // state.user = null;
  // state.isLogin = false;
  state.loading = false;
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: onGetUser,
    setGoogleModal: (state, action) => {
      state.googleModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, handlePending)
      .addCase(signup.fulfilled, onSignUp)
      .addCase(signup.rejected, handleRejected)

      .addCase(login.pending, handlePending)
      .addCase(login.fulfilled, onLogin)
      .addCase(login.rejected, handleRejected)

      .addCase(googleLogin.pending, handlePending)
      .addCase(googleLogin.fulfilled, onGoogleLogin)
      .addCase(googleLogin.rejected, handleRejected)

      .addCase(googleSignUp.pending, handleGooglePending)
      .addCase(googleSignUp.fulfilled, onGoogleSignUp)
      .addCase(googleSignUp.rejected, handleRejected)

      .addCase(logout.pending, handlePending)
      .addCase(logout.fulfilled, onLogout)
      .addCase(logout.rejected, handleRejected)

      .addCase(deleteAccount.pending, handlePending)
      .addCase(deleteAccount.fulfilled, onDeleteAccount)
      .addCase(deleteAccount.rejected, handleRejected)

      .addCase(forgetPassword.pending, handlePending)
      .addCase(forgetPassword.fulfilled, onForgetpassword)
      .addCase(forgetPassword.rejected, handleForgetPasswordRejected)

      .addCase(getUser.pending, handlePending)
      .addCase(getUser.fulfilled, onGetUser)
      .addCase(getUser.rejected, handleRejected);
  },
});

export const { setUser, setGoogleModal } = slice.actions;
export default slice.reducer;
