import React, { useState, useEffect } from "react";
import HomeNav from "../../components/home/HomeNav";
import "./calender.css";
import CalenderView from "./CalenderView";
import { FaCalendarAlt } from "react-icons/fa";
import { getCalenderEvents } from "../../redux/thunks/events";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/common/Loader";
import useFirebaseUser from "../../services/hooks/useFirebaseUser";

const Calender = () => {
  const dispatch = useDispatch();
  const { calenderEvents, loading, error } = useSelector(
    (state) => state.events
  );
  const { user } = useFirebaseUser();

  useEffect(() => {
    user && dispatch(getCalenderEvents(user?.currentUserId));
  }, [user]);

  const [date, setDate] = React.useState(new Date());
  console.log(calenderEvents);

  return (
    <div className="calender">
      <HomeNav />
      <div className="upcoming-scroll">
        <div className="container">
          <div className="my-calender-title-wrapper pt-5 pt-md-3 mt-5 mx-2 mx-md-5 mb-3 mb-md-5">
            <FaCalendarAlt className="my-calender-icon" size={32} />
            <h1 className="my-calender-title">My Calender</h1>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col col-sm-12 com-md-10 col-lg-6 mx-auto">
                <CalenderView
                  value={date}
                  onChange={setDate}
                  markers={calenderEvents}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Calender;
