import Visa from "../../assets/icons/visa.png";
import Mastercard from "../../assets/icons/master.png";
import Discover from "../../assets/icons/discover.png";
import Amex from "../../assets/icons/american-express.png";

export const getCreditCardPhoto = (card) => {
  if (card == "visa") {
    return Visa;
  }
  if (card == "mastercard") {
    return Mastercard;
  }
  if (card == "discover") {
    return Discover;
  }
  if (card == "amex") {
    return Amex;
  }
};
