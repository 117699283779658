import React from "react";
import "./signUpNav.css";
import LogoImg from "../../../assets/images/logo-light.png";
import { BsFillPersonFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const SignUpNav = () => {
  const navigate = useNavigate();
  return (
    <div className="signup-nav">
      <img
        className="signup-logo"
        src={LogoImg}
        alt="logo"
        onClick={() => navigate("/home")}
      />
      <div className="signup-nav-content">
        <div className="signup-nav-menu">
          <div>
            <a href="https://web.igotplans.co.uk" target="_blank">
              <p id="about-signup">About us</p>
            </a>
            <a href="https://web.igotplans.co.uk" target="_blank">
              <p id="contact-signup">Contact us</p>
            </a>
            <a href="https://manage.igotplans.co.uk/signup" target="_blank">
              <p id="contact-signup">Become Manager</p>
            </a>
            <div className="d-flex flex-column gap-1">
              {" "}
              <a
                href="https://play.google.com/store/apps/details?id=com.artclick.igotplan"
                target="_blank"
              >
                <p id="contact-us">Download App for Android</p>
              </a>
              <a
                href="https://apps.apple.com/pk/app/igotplans/id1663434251"
                target="_blank"
              >
                <p id="contact-us">Download App for Ios</p>
              </a>
            </div>
          </div>
        </div>
        <div className="signup-nav-account" onClick={() => navigate("/login")}>
          <div className="person-icon-wrapper">
            <BsFillPersonFill className="person-icon" />
          </div>
          <p>Login</p>
        </div>
      </div>
    </div>
  );
};

export default SignUpNav;
