import React, { useState, useRef, useEffect } from "react";

import "./settings.css";
import Error from "../../components/common/Error";
import InputField from "../../components/common/InputField";
import HomeNav from "../../components/home/HomeNav";
import { updateProfile } from "../../redux/thunks/profile";
// import {cleearAlerts} from '../.'

import { ToastContainer, toast } from "react-toastify";
import { HiOutlineUserCircle } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  deleteAccount,
  forgetPassword,
  deleteAccountByGoogle,
} from "../../redux/thunks/users";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useFirebaseUser from "../../services/hooks/useFirebaseUser";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter your name"),
  mobileNumber: Yup.string().required("Please enter your mobile number"),
  address: Yup.string().required("Please enter your address"),
  avatar: Yup.mixed().required("Profile Photo is required"),
});

const Settings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, success, loading } = useSelector((state) => state.profile);
  const { user } = useFirebaseUser();

  console.log(avatar);
  const fileRef = useRef(null);

  const toggle = () => setIsOpen(!isOpen);

  console.log(user?.name, user?.mobileNumber, user?.address, user?.avatar);

  return (
    <div>
      <HomeNav />
      <div className="container manager-profile-page">
        <h1>Settings</h1>
        <div className=" grey-border setting-main p-4 px-md-5  mt-4 ">
          {user && (
            <Formik
              initialValues={{
                name: user?.name,
                mobileNumber: user?.mobileNumber,
                address: user?.address,
                avatar: user?.avatar,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                values.avatar = avatar || user?.avatar;
                dispatch(updateProfile({ data: values, navigate, user }));
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                errors,
              }) => {
                return (
                  <div className="mx-md-5">
                    <div>
                      <div
                        className="upload-avatar  bg-white overflow-hidden mx-auto  "
                        onClick={() => {
                          fileRef.current.click();
                        }}
                      >
                        <img
                          className="avatar-img"
                          src={
                            avatar ? URL.createObjectURL(avatar) : user?.avatar
                          }
                          alt=""
                        />
                        <FaEdit className="modal-edit-icon" />
                      </div>
                      {errors.avatar && touched.avatar && (
                        <Error text={errors.avatar} />
                      )}
                    </div>
                    <input
                      type="file"
                      ref={fileRef}
                      hidden
                      onChange={(e) => {
                        setFieldValue("avatar", e.target.files[0]);
                        setAvatar(e.target.files[0]);
                      }}
                    />
                    <InputField
                      placeholder="Name"
                      className="signup-inputs"
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <Error text={errors.name} />
                    )}
                    <InputField
                      placeholder="Mobile Number"
                      className="signup-inputs"
                      type="text"
                      name="mobileNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobileNumber}
                    />
                    {errors.mobileNumber && touched.mobileNumber && (
                      <Error text={errors.mobileNumber} />
                    )}
                    <InputField
                      placeholder="City"
                      className="signup-inputs"
                      type="text"
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                    />
                    {errors.address && touched.address && (
                      <Error text={errors.address} />
                    )}
                    <div className=" my-4 mx-auto text-center">
                      <button
                        type="submit"
                        className="forget-send-btn"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          )}
        </div>
        <div className="mt-5 grey-border p-4 px-md-5 ">
          <h4 className="text-center">Update your password</h4>
          <div className="text-center mx-md-5">
            <InputField
              className="signup-inputs"
              value={user?.email}
              disabled
            />
            <button
              className="forget-send-btn my-4 "
              onClick={() => dispatch(forgetPassword(user?.email))}
            >
              Update
            </button>
          </div>
        </div>
        <div className="text-end mt-4">
          {/* {user?.registeredFromPlatform == "google" ? (
            <button
              className="delete-account-btn"
              onClick={() =>
                dispatch(deleteAccountByGoogle({ navigate, user }))
              }
            >
              Delete Account
            </button>
          ) : ( */}
          <button
            className="delete-account-btn"
            disabled={user?.registeredFromPlatform == "google"}
            // onClick={() => dispatch(deleteAccount({ navigate }))}
            onClick={toggle}
          >
            Delete Account
          </button>
          {/* )} */}
        </div>
      </div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(
              deleteAccount({ navigate, credential: { email, password }, user })
            );
          }}
        >
          <ModalHeader toggle={toggle}>
            <p className="text-center">Confirm your Identity</p>
          </ModalHeader>
          <ModalBody>
            <div>
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                className="signup-inputs login-inputs my-4"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                className="signup-inputs login-inputs my-4"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="forget-send-btn">
              Send
            </button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default Settings;
