import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./eventCarousel.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const EventDetailCarousel = ({ photo }) => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {photo?.map((img, index) => {
          return (
            <SwiperSlide key={index}>
              <img
                src={img}
                className="d-block w-100 h-100 carousel-img"
                alt="..."
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default EventDetailCarousel;

// import React, { useRef, useState } from "react";
// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/free-mode";
// import "swiper/css/navigation";
// import "swiper/css/thumbs";

// import "./eventCarousel.css";

// // import required modules
// import { FreeMode, Navigation, Thumbs } from "swiper/modules";

// const EventDetailCarousel = ({ photo }) => {
//   const [thumbsSwiper, setThumbsSwiper] = useState(null);

//   return (
//     <>
//       <Swiper
//         style={{
//           "--swiper-navigation-color": "#fff",
//           "--swiper-pagination-color": "#fff",
//         }}
//         loop={true}
//         spaceBetween={10}
//         navigation={true}
//         thumbs={{ swiper: thumbsSwiper }}
//         modules={[FreeMode, Navigation, Thumbs]}
//         className="mySwiper2"
//       >
//         {photo?.map((img, index) => {
//           return (
//             <SwiperSlide key={index}>
//               <img
//                 src={img}
//                 className="d-block w-100 h-100 carousel-img"
//                 alt="..."
//               />
//             </SwiperSlide>
//           );
//         })}
//       </Swiper>
//       <Swiper
//         onSwiper={setThumbsSwiper}
//         loop={true}
//         spaceBetween={10}
//         slidesPerView={4}
//         freeMode={true}
//         watchSlidesProgress={true}
//         modules={[FreeMode, Navigation, Thumbs]}
//         className="mySwiper"
//       >
//         {photo?.length > 1 &&
//           photo?.map((img, index) => {
//             return (
//               <SwiperSlide key={index}>
//                 <img src={img} className="d-block w-100 h-100" alt="..." />
//               </SwiperSlide>
//             );
//           })}
//       </Swiper>
//     </>
//   );
// };

// export default EventDetailCarousel;
