import axios from "axios";

const call = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const createCustomer = async (name, email) => {
  try {
    const response = await call.post("/customer", {
      name,
      email,
    });
    return response.data.customerId;
  } catch (err) {
    throw err;
  }
};

export const getCustomerCardsList = async (id) => {
  try {
    const response = await call.get(`/cards-list?customer_id=${id}`);
    return response.data.paymentMethods;
  } catch (error) {
    throw error;
  }
};

export const createPaymentMethod = async (
  cardNumber,
  expiryMonth,
  expiryYear,
  cvc
) => {
  try {
    const response = await call.post("/payment-method", {
      cardNumber,
      expiryMonth,
      expiryYear,
      cvc,
    });
    return response.data.paymentMethod;
  } catch (error) {
    throw error;
  }
};

export const attachCard = async (pmId, customerId) => {
  try {
    const response = await call.post("/attach-card", {
      pmId,
      customerId,
    });
    return response.data.paymentMethod;
  } catch (error) {
    throw error;
  }
};

export const chargeCustomer = async (pmId, customerId, amount) => {
  try {
    const response = await call.post("/charges", {
      pmId,
      customerId,
      amount,
    });
    return response.data.paymentIntent;
  } catch (error) {
    throw error;
  }
};

export const deleteCard = async (pmId) => {
  try {
    const response = await call.delete(`/payment-method?pmId=${pmId}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const createSessionUrl = async (data) => {
  try {
    const response = await call.post(`/create-checkout-session`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getPurchasePointsSession = async (data) =>
  call.post(`/purchase-points-session`, data);
