import { createAsyncThunk } from "@reduxjs/toolkit";
import firebaseService from "../../services/firebaseServices";
import { COLLECTIONS_NAMES } from "../../constants/firebase.constants.js";
import { auth } from "../../config/firebaseConfig";
import * as API from "../../services/api";
import { setGoogleModal, setUser } from "../slices/userSlice";
import { storage } from "../../config/firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  deleteUser,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
} from "firebase/auth";
import useFirebaseUser from "../../services/hooks/useFirebaseUser";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";

const { USERS } = COLLECTIONS_NAMES;

export const signup = createAsyncThunk(
  "users/signup",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data, navigate } = payload;

      const customerId = await API.createCustomer(data.name, data.email);
      const user = await firebaseService.signUp(data.email, data.password);
      const currentUserId = auth.currentUser.uid;

      const imageRef = ref(storage, `${data.avatar.lastModified}`);
      const res = await uploadBytes(imageRef, data.avatar);
      const avatarURL = await getDownloadURL(res.ref);

      console.log(avatarURL);

      const userData = {
        name: data.name,
        email: data.email,
        mobileNumber: data.mobileNumber,
        status: "active",
        about: null,
        address: data.address,
        avatar: avatarURL,
        calendarEvents: [],
        customerId: customerId,
        dateOfBirth: data.dateOfBirth,
        favorite: [],
        managersIds: [],
        notificationToken: null,
        paymentId: null,
        pointsAmount: "0",
        registeredFromPlatform: "email",
        userLevel: [1],
        currentUserId,
      };

      console.log(userData);
      const Data = await firebaseService.addDocumentWithCustomId(
        USERS,
        currentUserId,
        userData
      );
      dispatch(openSuccessMessage("Account Created Successfully"));
      // localStorage.setItem("authUser", JSON.stringify(userData));
      navigate("/");
      return userData;
    } catch (err) {
      console.log("err", err.message);
      dispatch(openErrorMessage("Error while Signing Up"));
      return rejectWithValue(err.message);
    }
  }
);

export const googleLogin = createAsyncThunk(
  "users/googleLogin",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { navigate } = payload;

      const user = await firebaseService.googleSignIn();
      console.log("google", user);
      const response = await firebaseService.getDocumentById(USERS, user.uid);
      console.log("res", response);

      if (response == undefined) {
        dispatch(setGoogleModal(true));
        console.log(2);

        return user;
      }
      if (response?.dateOfBirth && response?.mobileNumber) {
        console.log(1);
        // localStorage.setItem("authUser", JSON.stringify(response));
        // dispatch(setUser(response));
        console.log(response);
        navigate("/");
        return response;
      }
    } catch (error) {
      console.log("err", error);
    }
  }
);

export const googleSignUp = createAsyncThunk(
  "users/googleSignUp",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data, navigate } = payload;
      const { mobileNumber, dateOfBirth, address, user } = data;

      const customerId = await API.createCustomer(user.displayName, user.email);
      const currentUserId = auth.currentUser.uid;

      const userData = {
        name: user.displayName,
        email: user.email,
        mobileNumber: mobileNumber,
        status: "active",
        about: null,
        address: address,
        avatar: user.photoURL,
        calendarEvents: [],
        customerId: customerId,
        dateOfBirth: dateOfBirth,
        favorite: [],
        managersIds: [],
        notificationToken: null,
        paymentId: null,
        pointsAmount: "0",
        registeredFromPlatform: "google",
        userLevel: [1],
        currentUserId,
      };
      console.log("userData", userData);

      dispatch(setGoogleModal(false));

      const Data = await firebaseService.addDocumentWithCustomId(
        USERS,
        currentUserId,
        userData
      );
      navigate("/");
      return userData;
    } catch (error) {
      console.log(error);
      dispatch(openErrorMessage("Error while trying to logging in via google"));
      // return rejectWithValue(error);
    }
  }
);

export const login = createAsyncThunk(
  "users/login",
  async (data, { rejectWithValue, dispatch }) => {
    const { email, password, navigate } = data;
    try {
      const res = await firebaseService.signIn(email, password);
      const id = res?.user?.uid;
      const user = await firebaseService.getDocumentById(USERS, id);

      if (!user) {
        await firebaseService.logout();
        navigate("/login");
        throw new Error("User with this email does not exists!");
      }
      if (user.status != "active") {
        await firebaseService.logout();
        navigate("/login");
        // dispatch(
        //   openErrorMessage(
        //     "Your account has been suspended ... Please Contact Support"
        //   )
        // );
        throw new Error(
          "Your account has been suspended ... Please Contact Support"
        );
      } else {
        navigate("/");
      }

      return user;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const logout = createAsyncThunk(
  "users/logout",
  async (navigate, { rejectWithValue, dispatch }) => {
    try {
      await firebaseService.logout();
      // localStorage.removeItem("authUser");
      dispatch(openSuccessMessage("Logged out Sucessfully"));
      navigate("/login");
      return;
    } catch (err) {
      console.log(err);
      dispatch(openErrorMessage("Error while Logging Out"));
      return rejectWithValue(err.message);
    }
  }
);

export const getUser = createAsyncThunk(
  "users/getUser",
  async (userId, { rejectWithValue }) => {
    try {
      // const user = useFirebaseUser(userId);
      // console.log("hook", user);
      const user = await firebaseService.getDocumentById(USERS, userId);
      // localStorage.setItem("authUser", JSON.stringify(user));
      return user;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "users/deleteAccount",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { navigate, credential, user } = payload;

      const response = await firebaseService.deleteAccount(user, credential);
      console.log(response);

      // Delete User from Firebase Auth and Firestore Databases
      await firebaseService.deleteDocument(USERS, user?.currentUserId);

      dispatch(openSuccessMessage("Your Account has been deleted Sucessfully"));
      navigate("/login");
    } catch (error) {
      dispatch(
        openErrorMessage("Error in Deleting account ... try again later")
      );
      console.log(error);
    }
  }
);
export const deleteAccountByGoogle = createAsyncThunk(
  "users/deleteAccountByGoogle",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { navigate } = payload;
      const user = auth.currentUser;
      console.log(user);

      const response = await firebaseService.deleteGoogleAccount(user);

      // const response = await firebaseService.deleteAccount(googleData);
      // console.log(response);

      // Delete User from Firebase Auth and Firestore Databases
      await firebaseService.deleteDocument(USERS, user?.currentUserId);

      dispatch(openSuccessMessage("Your Account has been deleted Sucessfully"));
      navigate("/login");
    } catch (error) {
      dispatch(
        openErrorMessage("Error in Deleting account ... try again later")
      );
      console.log(error);
    }
  }
);

// export const addFavouriteEvent = createAsyncThunk(
//   "users/addFavouriteEvent",
//   async (data, { rejectWithValue }) => {
//     const { user, eventId } = data;
//     try {
//       var prevFavorite = [];
//       if (user.favorite) {
//         prevFavorite = [...user.favorite];
//       }
//       const favoriteData = [...prevFavorite, eventId];
//       firebaseService.updateDocument(USERS, user.currentUserId, {
//         favorite: favoriteData,
//       });
//       const newUserData = {
//         ...user,
//         favorite: favoriteData,
//       };
//       localStorage.setItem("authUser", JSON.stringify(newUserData));
//       return newUserData;
//     } catch (err) {
//       console.log(err);
//       return rejectWithValue(err.message);
//     }
//   }
// );

// export const removeFavouriteEvent = createAsyncThunk(
//   "users/removeFavouriteEvent",
//   async (data, { rejectWithValue }) => {
//     const { user, eventId } = data;
//     try {
//       const favoriteData = user.favorite.filter((fav) => fav !== eventId);
//       firebaseService.updateDocument(USERS, user.currentUserId, {
//         favorite: favoriteData,
//       });
//       const newUserData = {
//         ...user,
//         favorite: favoriteData,
//       };
//       localStorage.setItem("authUser", JSON.stringify(newUserData));
//       return newUserData;
//     } catch (err) {
//       console.log(err);
//       return rejectWithValue(err.message);
//     }
//   }
// );

export const forgetPassword = createAsyncThunk(
  "users/forgetPassword",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      console.log(payload);
      await sendPasswordResetEmail(auth, payload);
      dispatch(
        openSuccessMessage("Password reset email sent!... Check your email.")
      );
      return "Password reset email sent!... Check your email.";
    } catch (error) {
      console.log(error);
      dispatch(openErrorMessage("Sorry! Something went wrong"));
      return rejectWithValue(error.code);
    }
  }
);
