import React, { useEffect, useState } from "react";
import "./payment.css";
import Tickmark from "../../assets/images/tickmark.png";
import LogoImg from "../../assets/images/logo-light.png";
import PaymentForm from "../../components/payment/PaymentForm";
import BookingDetailsCard from "../../components/payment/BookingDetailsCard";
import HomeNav from "../../components/home/HomeNav";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { buyTicket } from "../../redux/thunks/tickets";
import { useSelector, useDispatch } from "react-redux";
import { getCustomerCardsList } from "../../redux/thunks/payment";
import useFirebaseUser from "../../services/hooks/useFirebaseUser";
import { getEvents } from "../../redux/thunks/events";

const Payment = () => {
  // const [paymentForm, setPaymentForm] = useState(1);

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // const event = location.state.event;
  const ticket = location.state?.ticket;
  const { user } = useFirebaseUser();
  // const { user } = useSelector((state) => state.users);
  const { formNo } = useSelector((state) => state.payment);
  const { events } = useSelector((state) => state.events);
  const { loading, success } = useSelector((state) => state.tickets);

  let event = null;
  events?.filter((e) => {
    if (e.id == params.eventId) {
      event = e;
    }
  });

  useEffect(() => {
    dispatch(getEvents());
  }, []);

  useEffect(() => {
    dispatch(getCustomerCardsList({ user }));
  }, [user]);

  // const handleFormClick = () => {
  //   paymentForm < 4 && setPaymentForm(paymentForm + 1);
  // };

  const handleClickPayment = () => {
    dispatch(
      buyTicket({
        userId: user.currentUserId,
        ticket,
        event,
      })
    );
  };

  return (
    <>
      <HomeNav searchbar={false} />
      <div className="payment">
        <div className="payment-container">
          <div className="payment-heading-wrapper">
            <div></div>
            <div className="payment-heading">
              <h2>Payments</h2>
            </div>
          </div>
          <section
            className={formNo == 3 ? "d-none" : "payment-content-wrapper"}
          >
            <div className="payment-form-wrapper">
              <PaymentForm
                // setPaymentForm={setPaymentForm}
                handleClickPayment={handleClickPayment}
                // paymentForm={paymentForm}
                loading={loading}
                ticket={ticket}
                event={event}
              />
              <div className={formNo == 3 ? "d-none" : "breaker-wrapper"}>
                <div className="breaker"></div>
              </div>
            </div>

            <div className="payment-booking-wrapper">
              <div className="booking-details-section">
                <h4>Your Booking Details</h4>
                <BookingDetailsCard ticket={ticket} />
                <div className="booking-current-total">
                  <p>Current Total (GBP)</p>
                  <p>£ {ticket?.price}</p>
                </div>
              </div>
            </div>
          </section>
          <section
            className={formNo == 3 ? "payment-success-wrapper" : "d-none"}
          >
            <div className="payment-success-section">
              <h5>
                Your payment <br /> has been processed successfully
              </h5>
              <img className="tickmark" src={Tickmark} alt="success" />
              <div className="payment-success-booking-details">
                <h6>Your booking details</h6>
                <BookingDetailsCard paymentForm={formNo} ticket={ticket} />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Payment;
