import React from "react";

function Error({ text, className }) {
  return (
    <div className={`text-danger ${className}`} style={{ fontSize: "12px" }}>
      {text}
    </div>
  );
}

export default Error;
