import React, { useEffect } from "react";
import HomeNav from "../../../components/home/HomeNav";
import "./upcoming.css";
import { Row, Col } from "reactstrap";
import { RiHeartLine, RiHeartFill } from "react-icons/ri";
import { FaTicketAlt } from "react-icons/fa";
import NoData from "../../utility/NoData";
// redux and friebase
import { useSelector, useDispatch } from "react-redux";
import { getUpcomingEvents } from "../../../redux/thunks/events";
import Loader from "../../../components/common/Loader";
import {
  addFavouriteEvent,
  removeFavouriteEvent,
} from "../../../redux/thunks/events";
import { getDateString } from "../../utility/getDateString";
import useFirebaseUser from "../../../services/hooks/useFirebaseUser";

const Previous = () => {
  const dispatch = useDispatch();
  const { upcomingEvents, loading, error } = useSelector(
    (state) => state.events
  );
  const { user } = useFirebaseUser();

  const addToFavHandler = (event) => {
    dispatch(
      addFavouriteEvent({
        user,
        eventId: event.id,
      })
    );
  };

  const removeToFavHandler = (event) => {
    dispatch(
      removeFavouriteEvent({
        user,
        eventId: event.id,
      })
    );
  };

  useEffect(() => {
    user && dispatch(getUpcomingEvents(user?.currentUserId));
  }, [user]);

  return (
    <div className="upcoming-events">
      <HomeNav />
      {loading ? <Loader /> : ""}
      <div className="upcoming-content  mt-5 pt-5 pt-lg-5">
        <h2 className="upcoming-title mb-3">Upcoming Events</h2>
        <div className="upcoming-scroll">
          {upcomingEvents?.length > 0 && !loading ? (
            <>
              {upcomingEvents?.map((event, index) => {
                const FavEvents = user?.favorite || [];
                const isFind = FavEvents.find(
                  (fav) => fav == event?.eventData?.id
                );
                return (
                  <Row key={index}>
                    <Col sm={12} md={10} lg={8} className="">
                      <div>
                        <Row className="mb-5">
                          <Col sm={12} md={4}>
                            <img
                              src={event?.eventData?.attachments?.[0]}
                              className="upcoming-event-img"
                            />
                          </Col>
                          <Col sm={12} md={8}>
                            <div className="d-flex justify-content-between mt-4 mt-md-0">
                              <div>
                                <h5 className="upcoming-card-title">
                                  {event?.eventData?.title}
                                </h5>
                                <p>{event?.eventData?.eventLocation}</p>
                              </div>
                              {isFind ? (
                                <RiHeartFill
                                  className="fav-icon fav-red-icon"
                                  onClick={() =>
                                    removeToFavHandler(event?.eventData)
                                  }
                                />
                              ) : (
                                <RiHeartLine
                                  className="fav-icon"
                                  onClick={() =>
                                    addToFavHandler(event?.eventData)
                                  }
                                />
                              )}
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-3">
                              <div>
                                <p className="upcoming-event-date">
                                  {getDateString(event?.eventData?.eventDate)}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <FaTicketAlt
                                  className="upcoming-ticket-icon"
                                  size={28}
                                />

                                <p className="upcoming-ticket-curr mb-0 ms-2">
                                  {" "}
                                  &#163; {event?.ticketData?.amountPaid}
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    {/* <Col sm={12} md={6} className="">
                      <div className="me-md-5"></div>
                    </Col> */}
                  </Row>
                );
              })}
            </>
          ) : upcomingEvents?.length == 0 && !loading ? (
            <NoData />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Previous;
