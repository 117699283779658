import React, { useState, useEffect } from "react";

import "./home.css";
import NoData from "../../assets/images/NoData.png";
import HomeNav from "../../components/home/HomeNav";
import HomeEvents from "../../components/home/HomeEvents";
import HomeFilters from "../../components/home/HomeFilters";
import Loader from "../../components/common/Loader";
import FeaturedEventlist from "../../components/home/FeaturedEventList";
import UpcomingEventList from "../../components/home/UpcomingEventList";
import {
  getEvents,
  addToCalenderEvents,
  addFavouriteEvent,
  removeFavouriteEvent,
} from "../../redux/thunks/events";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useFirebaseUser from "../../services/hooks/useFirebaseUser";

const Home = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { events, eventsLoading, error, favEvents } = useSelector(
    (state) => state.events
  );
  const { user } = useFirebaseUser();

  useEffect(() => {
    dispatch(getEvents());
  }, []);

  const addToCalHandler = (event) => {
    dispatch(
      addToCalenderEvents({
        userId: user.currentUserId,
        event,
        navigate,
      })
    );
  };

  const addToFavHandler = (event) => {
    dispatch(
      addFavouriteEvent({
        user,
        eventId: event.id,
      })
    );
  };

  const removeToFavHandler = (event) => {
    dispatch(
      removeFavouriteEvent({
        user,
        favEvents: user?.favorite,
        eventId: event.id,
      })
    );
  };

  const handleSearch = (input) => {
    setSearchQuery(input);
  };

  return (
    <>
      <HomeNav searchbar={true} handleSearch={handleSearch} />
      <div className="home-section">
        <div className="home-bg-wrapper"></div>
        <div className="home-container-wrapper">
          {eventsLoading ? (
            <Loader />
          ) : (
            events?.length > 0 && (
              <div className="home-filters home-container">
                <FeaturedEventlist
                  events={events
                    ?.filter((event) => event?.featured == true)
                    .filter((event) => {
                      return searchQuery.toLowerCase() === ""
                        ? event
                        : event.title.toLowerCase().includes(searchQuery);
                    })}
                  addToFavHandler={addToFavHandler}
                  removeToFavHandler={removeToFavHandler}
                  addToCalHandler={addToCalHandler}
                />
                <UpcomingEventList
                  events={events
                    ?.filter((event) => event?.featured == false)
                    .filter((event) => {
                      return searchQuery.toLowerCase() === ""
                        ? event
                        : event.title.toLowerCase().includes(searchQuery);
                    })}
                  heading={true}
                  addToFavHandler={addToFavHandler}
                  removeToFavHandler={removeToFavHandler}
                  addToCalHandler={addToCalHandler}
                />
              </div>
            )
          )}
          {/* <HomeFilters /> */}
        </div>
      </div>
    </>
  );
};

export default Home;
