import { createSlice } from "@reduxjs/toolkit";
import {
  getEvents,
  getPreviousEvents,
  getFavouriteEvents,
  addToCalenderEvents,
  getCalenderEvents,
  getUpcomingEvents,
  getEventManager,
  addFavouriteEvent,
  removeFavouriteEvent,
  getEventsByIds,
  getEventDetails,
} from "../thunks/events";

const initialState = {
  events: [],
  eventDetails: {},
  eventManager: {},
  prevEvents: [],
  upcomingEvents: [],
  favEvents: [],
  calenderEvents: null,
  loading: false,
  eventsLoading: false,
  error: null,
  success: null,
};

const handlePending = (state, action) => {
  state.loading = true;
  state.error = initialState.error;
  state.success = initialState.success;
  // state.events = [];
};
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};
const handleSuccess = (state, action) => {
  state.loading = false;
  state.success = action.payload;
};
const handleEventsPending = (state) => {
  state.eventsLoading = true;
  state.events = [];
};
const onGetEvents = (state, action) => {
  state.events = action.payload;
  state.eventsLoading = false;
};
const handleEventDetailsPending = (state, action) => {
  state.loading = false;
  state.error = initialState.error;
  state.success = initialState.success;
  state.eventDetails = {};
};
const onGetEventDetails = (state, action) => {
  state.eventDetails = action.payload;
  state.loading = false;
};
const onGetPreviousEvents = (state, action) => {
  state.prevEvents = action.payload;
  state.loading = false;
};

const onGetUpcomingEvents = (state, action) => {
  state.upcomingEvents = action.payload;
  state.loading = false;
};

const onGetFavouriteEvents = (state, action) => {
  state.favEvents = action.payload;
  state.loading = false;
};

const onAddToCalenderEvents = (state, action) => {
  state.loading = false;
};

const onGetCalenderEvents = (state, action) => {
  state.calenderEvents = action.payload;
  state.loading = false;
};
const handleGetEventManagerPending = (state, action) => {
  state.loading = true;
  state.eventManager = {};
};
const onGetEventManager = (state, action) => {
  state.loading = false;
  state.error = null;
  state.eventManager = action.payload;
};
const onAddFavouriteEvent = (state, action) => {
  // state.favEvents = action.payload;
  state.loading = false;
};

const onRemoveFavouriteEvent = (state, action) => {
  // state.favEvents = action.payload;
  state.loading = false;
};

const slice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, handleEventsPending)
      .addCase(getEvents.fulfilled, onGetEvents)
      .addCase(getEvents.rejected, handleRejected)

      .addCase(getEventDetails.pending, handleEventDetailsPending)
      .addCase(getEventDetails.fulfilled, onGetEventDetails)
      .addCase(getEventDetails.rejected, handleRejected)

      .addCase(getEventsByIds.pending, handlePending)
      .addCase(getEventsByIds.fulfilled, onGetEvents)
      .addCase(getEventsByIds.rejected, handleRejected)

      .addCase(getPreviousEvents.pending, handlePending)
      .addCase(getPreviousEvents.fulfilled, onGetPreviousEvents)
      .addCase(getPreviousEvents.rejected, handleRejected)

      .addCase(getUpcomingEvents.pending, handlePending)
      .addCase(getUpcomingEvents.fulfilled, onGetUpcomingEvents)
      .addCase(getUpcomingEvents.rejected, handleRejected)

      .addCase(getFavouriteEvents.pending, handlePending)
      .addCase(getFavouriteEvents.fulfilled, onGetFavouriteEvents)
      .addCase(getFavouriteEvents.rejected, handleRejected)

      .addCase(addToCalenderEvents.pending, handlePending)
      .addCase(addToCalenderEvents.fulfilled, onAddToCalenderEvents)
      .addCase(addToCalenderEvents.rejected, handleRejected)

      .addCase(getCalenderEvents.pending, handlePending)
      .addCase(getCalenderEvents.fulfilled, onGetCalenderEvents)
      .addCase(getCalenderEvents.rejected, handleRejected)

      .addCase(getEventManager.pending, handleGetEventManagerPending)
      .addCase(getEventManager.fulfilled, onGetEventManager)
      .addCase(getEventManager.rejected, handleRejected)

      .addCase(addFavouriteEvent.pending, handlePending)
      .addCase(addFavouriteEvent.fulfilled, onAddFavouriteEvent)
      .addCase(addFavouriteEvent.rejected, handleRejected)

      .addCase(removeFavouriteEvent.pending, handlePending)
      .addCase(removeFavouriteEvent.fulfilled, onRemoveFavouriteEvent)
      .addCase(removeFavouriteEvent.rejected, handleRejected);
  },
});

export const {} = slice.actions;
export default slice.reducer;
