import React, { useEffect } from "react";
import Toast from "../components/common/Toast";
import ScrollToTop from "../utils/ScrollToTop";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PrivateRoutes, PublicRoutes } from "./Routes";
import { useDispatch, useSelector } from "react-redux";
import useFirebaseUser from "../services/hooks/useFirebaseUser";
import LogoImg from "../assets/images/logo-light.png";
import { openErrorMessage } from "../redux/slices/toastSlice";

const IndexRoutes = () => {
  const dispatch = useDispatch();
  const { isLogin } = useSelector((state) => state.users);
  const { user, loading } = useFirebaseUser();
  // console.log(isLogin);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          backgroundColor: "var(--light-peach)",
        }}
      >
        <img src={LogoImg} style={{ borderRadius: "50%" }} />
        <p className="text-center"> Loading...</p>
      </div>
    );
  }
  if (user && user?.status != "active") {
    dispatch(
      openErrorMessage(
        "Your Account has been suspended ... Please Contact Support"
      )
    );
  }

  return (
    <>
      <Toast />
      {/* <ScrollToTop /> */}
      <BrowserRouter>
        <Routes>
          {user?.status != "active" &&
            PublicRoutes.map((route, i) => {
              return (
                <Route path={route.path} key={i} element={route.component} />
              );
            })}
          {user
            ? PrivateRoutes.map((route, i) => {
                return (
                  <Route path={route.path} key={i} element={route.component} />
                );
              })
            : PublicRoutes.map((route, i) => {
                return (
                  <Route path={route.path} key={i} element={route.component} />
                );
              })}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default IndexRoutes;
