import React, { useState, useEffect } from "react";
import InputField from "../../common/InputField";
import MasterCard from "../../../assets/images/mastercard.png";
import Visa from "../../../assets/images/visa.png";
import Paypal from "../../../assets/images/paypal.png";
import "./paymentForm.css";

import { Formik } from "formik";
import * as Yup from "yup";
import { MdDelete } from "react-icons/md";
import { RiAddCircleLine } from "react-icons/ri";
import { BiArrowBack } from "react-icons/bi";
import Error from "../../common/Error";
import { useDispatch, useSelector } from "react-redux";
import {
  chargeCustomerByPaymentMethod,
  createPaymentMethod,
  deletePaymentCard,
  chargeByWallet,
  chargeByCash,
} from "../../../redux/thunks/payment";
import { clearAlerts, setFormNo } from "../../../redux/slices/paymentSlice";
import { getCreditCardPhoto } from "../../../pages/utility/helperFunctions";
import { ToastContainer, toast } from "react-toastify";
import PaymentCard from "../PaymentCard";
import useFirebaseUser from "../../../services/hooks/useFirebaseUser";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  cardNumber: Yup.number()
    .required("Please Enter Your Card Number")
    .test("length", "Invalid Card Number", (value) => {
      if (!value) return false; // Return false if the value is empty or undefined
      const stringValue = String(value);
      return stringValue.length < 17;
    }),
  cvc: Yup.number()
    .required("Please Enter CVC. ")
    .test("length", "Invalid CVC", (value) => {
      if (!value) return false; // Return false if the value is empty or undefined
      const stringValue = String(value);
      return stringValue.length === 3;
    }),
  expiryMonth: Yup.number()
    .min(1, "Invalid month")
    .max(12, "Invalid month")
    .required("Month is required"),
  expiryYear: Yup.number()
    // .min(new Date().getFullYear(), "Card has expired")
    .required("Year is required")
    .test("expiry-date", "Card has expired", function (value) {
      const { expiryMonth } = this.parent;
      const currentYear = new Date().getFullYear().toString().substr(-2);
      const currentMonth = new Date().getMonth() + 1;

      if (value > currentYear) {
        return true;
      } else if (value === currentYear && expiryMonth >= currentMonth) {
        return true;
      }

      return false;
    }),
  // cardNumber: Yup.string()
  //   .required("Please enter card number")
  //   .max(17, "Card Number must be less then 17 digits"),
  // // streetAddress: Yup.string().required("Please street address"),
  // expiryYear: Yup.string().required("Please enter Expiry Year"),
  // expiryMonth: Yup.string().required("Please enter Expiry Month"),
  // cvc: Yup.string().required("Please enter CVC"),
});

const PaymentForm = ({
  setPaymentForm,
  // paymentForm,
  handleClickPayment,
  ticket,
  event,
}) => {
  const [active, setActive] = useState({
    no: 0,
    data: {},
  });
  console.log(active);
  const { cards, formNo, loading } = useSelector((state) => state.payment);
  const { user } = useFirebaseUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log(active);

  return (
    <div className="payment-form">
      <section className={formNo == 1 ? "d-block" : "d-none"}>
        <div
          className={
            active.no == "card"
              ? "active-payment payment-card-details payment-on-cash  mb-3 d-flex justify-content-start align-items-center gap-3"
              : "payment-card-details payment-on-cash  mb-3 d-flex justify-content-start align-items-center gap-3"
          }
          onClick={() => setActive({ no: "card", data: {} })}
        >
          <p className="mb-0">Payment through Card</p>
          {/* <RiAddCircleLine className="add-new-card-icon" /> */}
        </div>
        {cards?.map((card) => {
          return (
            <PaymentCard card={card} active={active} setActive={setActive} />
          );
        })}
        <div
          className={
            active.no == "cash"
              ? "active-payment payment-card-details payment-on-cash  mb-3 d-flex justify-content-start align-items-center gap-3"
              : "payment-card-details payment-on-cash  mb-3 d-flex justify-content-start align-items-center gap-3"
          }
          onClick={() => setActive({ no: "cash", data: {} })}
        >
          <p className=" mb-0">Payment on Cash</p>
        </div>
        <div
          className={
            active.no == "wallet"
              ? "active-payment payment-card-details payment-on-cash mb-3 d-flex justify-content-start align-items-center gap-3"
              : "payment-card-details payment-on-cash mb-3 d-flex justify-content-start align-items-center gap-3"
          }
          onClick={() => setActive({ no: "wallet", data: {} })}
        >
          <p className="mb-0">Payment on Wallet Points</p>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <button
            className="payment-form-btn place-order-btn"
            disabled={
              loading
                ? true
                : active.no == 0
                ? true
                : active.no == "cash"
                ? !ticket.cashPayment
                : active.no == "wallet" && user?.pointsAmount < ticket.price
            }
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              active.no == "cash"
                ? dispatch(chargeByCash({ ticket, event, user, navigate }))
                : active.no == "wallet"
                ? dispatch(chargeByWallet({ ticket, user, event, navigate }))
                : dispatch(
                    chargeCustomerByPaymentMethod({
                      ticket,
                      user,
                      event,
                      navigate,
                    })
                  );
            }}
          >
            Place Order
          </button>
        </div>
      </section>
      <section
        className={
          formNo == 2 ? "d-block payment-details" : "d-none payment-details"
        }
      >
        <div className="text-start mb-3">
          <BiArrowBack
            onClick={() => dispatch(setFormNo(1))}
            style={{ cursor: "pointer" }}
          />
        </div>
        <h3 className="text-start payment-details-title">Add Payment Method</h3>
        <Formik
          initialValues={{
            cardNumber: "",
            expiryYear: "",
            expiryMonth: "",
            cvc: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            console.log(values);
            dispatch(createPaymentMethod({ data: values, user }));
            resetForm();
            // handleClick();
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            touched,
            errors,
          }) => {
            return (
              <div>
                <InputField
                  className="payment-form-input"
                  placeholder="Card number"
                  type="number"
                  name="cardNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cardNumber}
                />
                {errors.cardNumber && touched.cardNumber && (
                  <Error text={errors.cardNumber} />
                )}
                <div className="lower-payment-form-inputs">
                  <div className="d-flex flex-column">
                    <InputField
                      className="payment-form-input"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.expiryMonth}
                      name="expiryMonth"
                      placeholder="Expiry Month"
                    />
                    {errors.expiryMonth && touched.expiryMonth && (
                      <Error text={errors.expiryMonth} />
                    )}
                  </div>
                  <div className="d-flex flex-column">
                    <InputField
                      className="payment-form-input"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.expiryYear}
                      name="expiryYear"
                      placeholder="Expiry Year"
                    />
                    {errors.expiryYear && touched.expiryYear && (
                      <Error text={errors.expiryYear} />
                    )}
                  </div>
                </div>
                <InputField
                  className="payment-form-input"
                  placeholder="cvc"
                  name="cvc"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cvc}
                />
                {errors.cvc && touched.cvc && <Error text={errors.cvc} />}

                <div className="payment-form-btn-wrapper">
                  <button
                    className="payment-form-btn mt-5"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            );
          }}
        </Formik>
      </section>
      {/* <section
          className={
            paymentForm == 2
              ? "d-block payment-details"
              : "d-none payment-details"
          }
        >
          <h3 className="text-start payment-details-title">
            Your Payment Details
          </h3>
          <div
            className={
              active == 1
                ? "payment-card-details mb-3 active-payment"
                : "payment-card-details mb-3"
            }
            onClick={() => setActive(1)}
          >
            <img src={MasterCard} />
            <div>
              <p>**** **** **** 5871</p>
              <p className="payment-expiry">Expires 08/27</p>
            </div>
          </div>
          <div
            className={
              active == 2
                ? "payment-card-details mb-3 active-payment"
                : "payment-card-details mb-3"
            }
            onClick={() => setActive(2)}
          >
            <img src={Visa} />
            <div>
              <p>**** **** **** 3268</p>
              <p className="payment-expiry">Expires 08/27</p>
            </div>
          </div>
          <div
            className={
              active == 3
                ? "payment-card-details mb-3 active-payment"
                : "payment-card-details mb-3"
            }
            onClick={() => setActive(3)}
          >
            <img src={Paypal} />
            <div className="paypal-method">
              <p className="mt-3">lucysth1@icloud.com</p>
            </div>
          </div>
          <div className="payment-form-btn-wrapper">
            <button
              className="payment-form-btn mt-5"
              type="button"
              onClick={handleClickPayment}
            >
              {loading ? "loading..." : " Confirm"}
            </button>
          </div> */}
      {/* <ToastContainer /> */}
    </div>
  );
};

export default PaymentForm;
