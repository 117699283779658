import React, { useState, useEffect, useMemo } from "react";

import "./eventDetails.css";
import HomeNav from "../../../components/home/HomeNav";
import {
  addFavouriteEvent,
  getEventDetails,
  getEvents,
  getFavouriteEvents,
  removeFavouriteEvent,
} from "../../../redux/thunks/events";
import {
  getCalenderEvents,
  getEventManager,
} from "../../../redux/thunks/events";
import EventDetailCarousel from "../../../components/events/EventDetailCarousel";

import { RiHeartLine, RiHeartFill } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import EventMap from "../../../components/events/EventMap";
import EventManager from "../../../components/events/EventManager";
import useFirebaseUser from "../../../services/hooks/useFirebaseUser";

const EventDetails = () => {
  const [isFav, setIsFav] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const locEvent = location.state?.event;
  const { user } = useFirebaseUser();
  const {
    calenderEvents,
    events,
    error,
    loading,
    eventManager,
    favEvents,
    eventDetails,
  } = useSelector((state) => state.events);

  useEffect(() => {
    const isFind = user?.favorite?.find((fav) => fav == params.id);
    if (isFind) {
      setIsFav(true);
    } else {
      setIsFav(false);
    }
    user && dispatch(getCalenderEvents(user?.currentUserId));
  }, [user]);

  useEffect(() => {
    const resolve = (event) => {
      dispatch(getEventManager({ managersId: event?.managersId?.[0] }));
    };
    dispatch(getEventDetails({ eventId: params.id, resolve }));
  }, []);

  const isBooked = useMemo(() => {
    if (!calenderEvents) {
      return;
    }
    let isBooked = null;
    // console.log("calEvents", calenderEvents);
    const isFind = calenderEvents.findIndex(
      (ev) => ev.eventId == eventDetails?.id
    );
    console.log("isFind", isFind);
    if (isFind != -1) {
      isBooked = "booked";
    } else {
      isBooked = "available";
    }
    return isBooked;
  }, [calenderEvents, eventDetails, loading]);

  const addToFavHandler = (event) => {
    dispatch(
      addFavouriteEvent({
        user,
        eventId: event.id,
      })
    );
  };

  const removeToFavHandler = (event) => {
    dispatch(
      removeFavouriteEvent({
        user,
        favEvents,
        eventId: event.id,
      })
    );
  };

  console.log("eventDetails", eventDetails);
  console.log("Loading", loading);
  const title = eventDetails?.title || locEvent?.title;
  const attachments = eventDetails?.attachments || locEvent?.attachments;

  return (
    <div>
      <HomeNav searchbar={false} />
      <div className="home-section">
        <div className="home-bg-wrapper"></div>
        <div className="container event-details-container">
          <div className="event-details">
            <div className="event-details-head">
              <p className="event-details-title">{title}</p>
              <div>
                {user?.status == "active" &&
                  (isFav ? (
                    <RiHeartFill
                      className="fav-icon fav-red-icon"
                      onClick={() => removeToFavHandler(eventDetails)}
                    />
                  ) : (
                    <RiHeartLine
                      className="fav-icon"
                      onClick={() => addToFavHandler(eventDetails)}
                    />
                  ))}
              </div>
            </div>

            <div className="event-details-section">
              <div className="event-details-img">
                <EventDetailCarousel photo={attachments} />
              </div>

              <div className="event-details-content">
                {eventDetails?.id && (
                  <EventManager eventManager={eventManager} />
                )}
                <p className="event-details-desc">
                  {eventDetails?.description}
                </p>
                <div className="event-details-lower">
                  <p>Location: {eventDetails?.eventLocation}</p>
                  <p>Event Type: {eventDetails?.eventType}</p>
                  <p>Event Level: {eventDetails?.eventLevel}</p>
                  <p>Date: {eventDetails?.date}</p>
                  <p>Time: {eventDetails?.startTime}</p>
                  {isBooked != null && !loading && user?.status == "active" && (
                    <div>
                      {isBooked == "booked" && (
                        <p className="already-bought-ticket px-3 mt-3 mt-md-5 text-center">
                          You have already bought this ticket
                        </p>
                      )}
                      {isBooked == "available" && (
                        <Link
                          to={`/event-details/${eventDetails?.id}/tickets`}
                          state={{ eventDetails }}
                        >
                          <button className="event-details-btn">
                            Buy Ticket
                          </button>
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              {eventDetails?.id && (
                <EventMap location={eventDetails?.eventLocation} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
