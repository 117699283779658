import React from "react";
import "./calenderEvent.css";
import { Row, Col } from "reactstrap";
// redux and friebase
import { getDateString } from "../../utility/getDateString";
import { Link } from "react-router-dom";

const CalenderEvent = ({ event }) => {
  return (
    <div className="mb-5">
      <Row className="mt-5 w-100">
        <Col sm={12} md={10} className="">
          <div>
            <Row>
              <Col sm={12} md={5}>
                <Link to={`/event-details/${event?.id}`} state={{ event }}>
                  <img
                    src={event?.attachments?.[0]}
                    className="upcoming-event-img"
                  />
                </Link>
              </Col>
              <Col sm={12} md={7}>
                <div className="d-flex justify-content-between mt-4 mt-md-0">
                  <div>
                    <Link to={`/event-details/${event?.id}`} state={{ event }}>
                      <h5 className="upcoming-card-title">{event?.title}</h5>
                    </Link>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <p className="upcoming-event-date mb-0">
                    {getDateString(event?.eventDate)}
                  </p>
                  <div className="d-flex align-items-center">
                    <p className="upcoming-ticket-curr mb-0 ms-2">
                      {event?.startTime}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CalenderEvent;
