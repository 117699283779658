import React, { useEffect } from "react";

import HomeNav from "../../../components/home/HomeNav";
import "./favourite.css";
import NoData from "../../utility/NoData";
import Loader from "../../../components/common/Loader";
import UserIcon from "../../../assets/icons/user.png";
import {
  getEventsByIds,
  getFavouriteEvents,
  removeFavouriteEvent,
} from "../../../redux/thunks/events";
import { getFavOrganisers } from "../../../redux/thunks/managers";

import { RiHeartFill } from "react-icons/ri";
import { useNavigate, Link } from "react-router-dom";
// redux and friebase
import { useSelector, useDispatch } from "react-redux";
import useFirebaseUser from "../../../services/hooks/useFirebaseUser";

const FavouriteEvents = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useFirebaseUser();

  const { favEvents, events, loading } = useSelector((state) => state.events);
  const { favOrganisers } = useSelector((state) => state.managers);

  useEffect(() => {
    user && dispatch(getFavouriteEvents(user));
  }, [user]);

  useEffect(() => {
    dispatch(getEventsByIds(favEvents));
    user && dispatch(getFavOrganisers({ user }));
  }, [favEvents, user]);

  const removeToFavHandler = (event) => {
    dispatch(
      removeFavouriteEvent({
        user,
        favEvents,
        eventId: event.id,
      })
    );
  };

  return (
    <div>
      <HomeNav searchbar={false} />

      <div className="home-section">
        <div className="home-bg-wrapper"></div>
        <div className="fav-container">
          <div className="ticket-page">
            <h1 className="fav-page-heading">My Favourite Events</h1>

            <div className="mb-5">
              {favEvents?.length > 0 ? (
                <>
                  {favEvents?.map((event, index) => {
                    return (
                      <div key={index} className="fav-event mt-5">
                        <div className="fav-img">
                          <img src={event?.attachments?.[0]} />
                        </div>
                        <div className="d-flex gap-2 fav-content">
                          <div>
                            <h5>{event?.title}</h5>
                            <p>{event?.eventLocation}</p>
                            <Link
                              to={`/event-details/${event?.id}`}
                              state={{ event }}
                            >
                              <button className="event-details-btn">
                                Buy Ticket
                              </button>
                            </Link>
                          </div>
                          <RiHeartFill
                            className="fav-icon fav-red-icon"
                            onClick={() => removeToFavHandler(event)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                favEvents?.length == 0 && <NoData />
              )}
            </div>

            {favOrganisers?.length > 0 && (
              <>
                <h2 className="fav-page-heading">Favourite Organisers</h2>
                <div>
                  {favOrganisers?.map((manager) => {
                    return (
                      <div
                        className="d-flex align-items-center gap-3 mt-4"
                        style={{ cursor: "pointer" }}
                        key={manager?.id}
                        onClick={() =>
                          navigate(`/favourite/${manager?.id}/manager`)
                        }
                      >
                        <div>
                          <div className="manager-avatar">
                            <img src={manager?.img || UserIcon} />
                          </div>
                        </div>
                        <div>
                          <h4>{manager?.name}</h4>
                          {/* <p>{manager?.bio}</p> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavouriteEvents;
