import React, { useEffect, useState } from "react";
import "./homeNav.css";
import LogoImg from "../../../assets/images/logo-light.png";
import InputField from "../../common/InputField";
import { BsFillPersonFill } from "react-icons/bs";
import { BiBell } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../redux/thunks/users";
import useFirebaseUser from "../../../services/hooks/useFirebaseUser";
import { getMyNotifications } from "../../../redux/thunks/notifications";

const HomeNav = ({ searchbar, handleSearch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notes, loading } = useSelector((state) => state.notifications);
  const { user } = useFirebaseUser();

  useEffect(() => {
    dispatch(getMyNotifications({ user }));
  }, [dispatch, user]);

  return (
    <div className="homeNav">
      <div className="homeNav-wrapper">
        <img
          className="homeNav-igp-logo"
          src={LogoImg}
          onClick={() => navigate("/home")}
        />
        <form className={searchbar ? "home-search" : "d-none"}>
          <InputField
            className="homeNav-search-input"
            placeholder="Search..."
            onChange={(e) => handleSearch(e.target.value)}
          />
          {/* <button hidden type="submit"></button> */}
        </form>
        <div className="homeNav-account-section">
          <div className="homeNav-account">
            <div className="d-flex align-items-center gap-1">
              {user?.status == "active" && (
                <div class="dropdown">
                  <BiBell
                    className="bell-icon"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  />
                  <ul
                    class="dropdown-menu mt-1 pb-3 p-3 note-dropdown  "
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {notes?.length > 0 && !loading ? (
                      notes?.map((note, index) => {
                        return (
                          <>
                            <li
                              className="mb-1 dropdown-item note-item "
                              key={index}
                              // onClick={() => {
                              //   note?.subjectType == "event" &&
                              //     navigate(`/event-details/${note?.subjectId}`);
                              // }}
                            >
                              <div className="d-flex align-items-start gap-2">
                                <div style={{ flex: "1" }}>
                                  <div className="note-avatar">
                                    <img src={note?.manager?.img} />
                                  </div>
                                </div>
                                <div
                                  className="d-flex flex-column flex-wrap "
                                  style={{ flex: "4" }}
                                >
                                  <p className="note-title">{note?.title}</p>
                                  <p className="note-message">
                                    {note?.message}
                                  </p>
                                  <p className="note-subject">
                                    {note?.subjectType}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <hr className="m-0" />
                          </>
                        );
                      })
                    ) : loading ? (
                      <div className="d-flex align-items-center w-100 h-100">
                        <p className="text-center w-100">
                          <div class="spinner">
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </p>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center w-100 h-100">
                        <p className="text-center w-100">
                          <span className="grey-clr">No new Notification</span>
                        </p>
                      </div>
                    )}
                  </ul>
                </div>
              )}
            </div>
            {user?.status !== "active" ? (
              <div
                className="signup-nav-account"
                onClick={() => navigate("/login")}
              >
                <div className="person-icon-wrapper">
                  <BsFillPersonFill className="person-icon" />
                </div>
                <p>Login</p>
              </div>
            ) : (
              <div class="dropdown">
                <p
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {user?.avatar ? (
                    <div className="user-profile-img">
                      <img src={user?.avatar} style={{ cursor: "pointer" }} />
                    </div>
                  ) : (
                    <div className="homeNav-person-icon-wrapper">
                      <BsFillPersonFill className="person-icon" />
                    </div>
                  )}
                </p>
                <ul
                  class="dropdown-menu mt-1 pb-3 p-3 home-dropdown "
                  aria-labelledby="dropdownMenuButton1"
                >
                  <div className="d-flex gap-3 ">
                    <div>
                      <li className="mb-1" onClick={() => navigate("/")}>
                        <p class="dropdown-item">
                          <span>Explore Events</span>
                        </p>
                      </li>
                      <li
                        className="mb-1"
                        onClick={() => navigate("/my-tickets")}
                      >
                        <p class="dropdown-item">
                          <span>My Tickets</span>
                        </p>
                      </li>
                      <li
                        className="mb-1"
                        onClick={() => navigate("/favourite")}
                      >
                        <p class="dropdown-item">
                          <span>My Favourites</span>
                        </p>
                      </li>
                      <li
                        className="mb-1"
                        onClick={() => navigate("/previous-events")}
                      >
                        <p class="dropdown-item">
                          <span>My Previous Events</span>
                        </p>
                      </li>

                      <li
                        className="mb-1"
                        onClick={() => navigate("/upcoming-events")}
                      >
                        <p class="dropdown-item">
                          <span>My Upcoming Events</span>
                        </p>
                      </li>
                    </div>
                    <div>
                      <li
                        className="mb-1"
                        onClick={() =>
                          navigate(`/profile/${user.currentUserId}`)
                        }
                      >
                        <p class="dropdown-item">
                          <span>My Profile</span>
                        </p>
                      </li>
                      <li className="mb-1" onClick={() => navigate("/wallet")}>
                        <p class="dropdown-item">
                          <span>My Wallet</span>
                        </p>
                      </li>

                      <li
                        className="mb-1"
                        onClick={() => navigate("/calender")}
                      >
                        <p class="dropdown-item">
                          <span>My Calender</span>
                        </p>
                      </li>
                      <li
                        className="mb-1"
                        onClick={() => navigate("/settings")}
                      >
                        <p class="dropdown-item">
                          <span>Settings</span>
                        </p>
                      </li>
                      <li
                        className="mb-1"
                        onClick={() => navigate("/feedback")}
                      >
                        <p class="dropdown-item">
                          <span>Feedback</span>
                        </p>
                      </li>
                      <li onClick={() => dispatch(logout(navigate))}>
                        <p class="dropdown-item text-danger">
                          <span>Logout</span>
                        </p>
                      </li>
                    </div>
                  </div>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeNav;
