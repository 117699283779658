import { createSlice } from "@reduxjs/toolkit";
import {
  followManager,
  getFavManager,
  getFavOrganisers,
  getManagerEvents,
  getManagerFollowersCount,
  unFollowManager,
} from "../thunks/managers";

const initialState = {
  loading: false,
  error: null,
  succes: null,
  favManager: {},
  favOrganisers: [],
  managerEvents: [],
  followersCount: 0,
  follow: null,
};

const handlePending = (state, action) => {
  state.loading = true;
  state.error = null;
};
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};
const handleGetEventPending = (state, action) => {
  state.loading = true;
  state.error = null;
  state.managerEvents = [];
};
const onGetFavManagers = (state, action) => {
  state.loading = false;
  state.favManager = action.payload;
};
const onGetFavOrganisers = (state, action) => {
  state.loading = false;
  state.favOrganisers = action.payload;
};
const onGetEvent = (state, action) => {
  state.loading = false;
  state.managerEvents = action.payload;
};
const onGetFollowersCount = (state, action) => {
  state.loading = false;
  state.followersCount = action.payload;
};
const onGetFollowManager = (state, action) => {
  state.loading = false;
};
const onGetUnFollowManager = (state, action) => {
  state.loading = false;
};

const slice = createSlice({
  name: "managers",
  initialState,
  reducers: {
    setFollow: (state, action) => {
      state.follow = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFavManager.pending, handlePending)
      .addCase(getFavManager.fulfilled, onGetFavManagers)
      .addCase(getFavManager.rejected, handleRejected)

      .addCase(getFavOrganisers.pending, handlePending)
      .addCase(getFavOrganisers.fulfilled, onGetFavOrganisers)
      .addCase(getFavOrganisers.rejected, handleRejected)

      .addCase(getManagerEvents.pending, handleGetEventPending)
      .addCase(getManagerEvents.fulfilled, onGetEvent)
      .addCase(getManagerEvents.rejected, handleRejected)

      .addCase(getManagerFollowersCount.pending, handlePending)
      .addCase(getManagerFollowersCount.fulfilled, onGetFollowersCount)
      .addCase(getManagerFollowersCount.rejected, handleRejected)

      .addCase(followManager.pending, handlePending)
      .addCase(followManager.fulfilled, onGetFollowManager)
      .addCase(followManager.rejected, handleRejected)

      .addCase(unFollowManager.pending, handlePending)
      .addCase(unFollowManager.fulfilled, onGetUnFollowManager)
      .addCase(unFollowManager.rejected, handleRejected);
  },
});

export const { setFollow } = slice.actions;
export default slice.reducer;
