import React, { useState } from "react";

import "./wallet.css";
import InputField from "../../components/common/InputField";
import Error from "../../components/common/Error";
import { IoWallet } from "react-icons/io5";
import HomeNav from "../../components/home/HomeNav";
import useFirebaseUser from "../../services/hooks/useFirebaseUser";
import { useApi } from "../../services/hooks";
import { getPurchasePointsSession } from "../../services/api";
import { messages } from "../../constants";
import { Spinner } from "reactstrap";

const Wallet = () => {
  const [addPoints, setAddPoints] = useState(false);
  const [points, setPoints] = useState("");
  const [pointsError, setPointsError] = useState("");
  const session = useApi(getPurchasePointsSession);
  const { user } = useFirebaseUser();

  const handleAddPoints = async (e) => {
    e.preventDefault();
    if (points < 2 || points > 1000)
      return setPointsError(messages.invalidPonits);
    const data = await session.request({
      points,
      userId: user.currentUserId,
    });
    if (data && data.url) {
      window.location.href = data.url;
    }
  };

  const handleChangePonits = (e) => {
    const value = e.target.value;
    setPoints(value);
    if (!Number.isInteger(parseFloat(value))) {
      setPointsError("Points must be a whole number");
    } else if (value < 2 || value > 1000) {
      setPointsError(messages.invalidPonits);
    } else {
      setPointsError("");
    }
  };

  return (
    <div>
      <HomeNav />
      <div className="container manager-profile-page">
        <div className="d-flex justify-content-between align-items-end">
          <h1 className="mb-0">Wallet</h1>
        </div>

        <div className=" grey-border setting-main py-5 px-4  mt-4 ">
          <div className="d-flex align-items-center gap-2">
            <div className="wallet-wrapper">
              <IoWallet className="wallet-icon" />
            </div>
            <h4 className="mb-0">Balance</h4>
          </div>
          <div className="my-5 text-center">
            <h1 className="mb-0 points-bold">
              {Number(user?.pointsAmount || 0) * 2}
            </h1>
            <p>Points</p>
            <button
              className="follow-btn mt-0 py-1"
              onClick={() => setAddPoints(!addPoints)}
            >
              Add points
            </button>
          </div>
        </div>
        {addPoints && (
          <div className=" grey-border setting-main py-5 px-4  mt-4 ">
            <form onSubmit={handleAddPoints}>
              <div className="text-center">
                <InputField
                  type="number"
                  name="ponits"
                  value={points}
                  onChange={handleChangePonits}
                  className="signup-inputs mt-2"
                  placeholder="Add Points"
                  style={{ maxWidth: "350px" }}
                />
                {!!pointsError && <Error text={pointsError} />}
              </div>
              <div className="d-flex justify-content-center mt-4">
                {session.loading ? (
                  <Spinner animation="border" role="status" variant="primary">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <button
                    type="submit"
                    className="follow-btn"
                    disabled={!!pointsError}
                  >
                    Purchase
                  </button>
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Wallet;
