import React, { useEffect } from "react";

import "./App.css";
import IndexRoutes from "./routes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const showDownloadNotification = (device) => {
  let link =
    device === "ios"
      ? "https://apps.apple.com/us/app/igotplans/id1663434251"
      : "https://play.google.com/store/apps/details?id=com.artclick.igotplan";

  toast.info(
    <div>
      <div>Download our IGotPlans mobile app</div>
      <button
        className="btn btn-light btn-sm"
        onClick={() => {
          window.open(link, "_blank");
        }}
      >
        Download
      </button>
    </div>,
    {
      position: toast.POSITION.TOP_CENTER,
      closeButton: false,
    }
  );
};

const App = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    if (isMobile) {
      // Check if it's an iOS device
      const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
      if (isIOS) {
        showDownloadNotification("ios");
      } else {
        showDownloadNotification("android");
      }
    }
  }, []);
  return (
    <>
      <IndexRoutes />
    </>
  );
};

export default App;
