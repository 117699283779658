import { combineReducers } from "redux";

import events from "./slices/eventsSlice";
import users from "./slices/userSlice";
import tickets from "./slices/ticketsSlice";
import managers from "./slices/managersSlice";
import profile from "./slices/profileSlice";
import payment from "./slices/paymentSlice";
import toast from "./slices/toastSlice";
import notifications from "./slices/notificationsSlice";

export default combineReducers({
  profile,
  events,
  users,
  tickets,
  managers,
  payment,
  toast,
  notifications,
});
