import { createAsyncThunk } from "@reduxjs/toolkit";
import firebaseService from "../../services/firebaseServices";
import { auth } from "../../config/firebaseConfig";
import { COLLECTIONS_NAMES } from "../../constants/firebase.constants.js";
import { setFollow } from "../slices/managersSlice";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";

const { EVENTS, TICKETS, USERS, CALLAPPCOL, MANAGERS } = COLLECTIONS_NAMES;

export const getFavManager = createAsyncThunk(
  "managers/getFavManager",
  async (payload, { rejectWithValue }) => {
    try {
      console.log(payload);
      const { id } = payload;
      const response = await firebaseService.getDocumentById(MANAGERS, id);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getFavOrganisers = createAsyncThunk(
  "managers/getFavOrganisers",
  async (payload, { rejectWithValue }) => {
    try {
      console.log(payload);
      const { user } = payload;

      let managers = [];
      const getManagers = await Promise.all(
        user.managersIds.map(async (managerId, index) => {
          const response = await firebaseService.getDocumentById(
            MANAGERS,
            managerId
          );
          managers.push(response);
        })
      );
      console.log("managers", managers);
      return managers;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getManagerEvents = createAsyncThunk(
  "manager/getManagerEvents",
  async (payload, { rejectWithValue }) => {
    try {
      console.log(payload.id);
      //   let count = 0;
      let myEvents = [];
      const events = await firebaseService.getDocuments(EVENTS);
      events.map((event) => {
        if (event.createdBy == payload.id) {
          //   count++;
          myEvents.push(event);
        }
      });
      const filteredEvents = myEvents.filter(
        (event) => new Date(event.eventDate) > new Date()
      );
      const sortedEvents = filteredEvents?.sort((a, b) => {
        const dateA = new Date(a.eventDate);
        const dateB = new Date(b.eventDate);
        return dateA - dateB;
      });
      console.warn(sortedEvents);
      return sortedEvents;
      // return filteredEvents;
      // return myEvents;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getManagerFollowersCount = createAsyncThunk(
  "managers/getManagersFollowersCount",
  async (payload, { rejectWithValue }) => {
    try {
      //bad solution should use query
      console.log(payload);
      let count = 0;
      const followers = await firebaseService.queryDocumentInArray(
        USERS,
        "managersIds",
        payload
      );
      console.log(followers);
      return followers.length;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const checkFollowManagerStatus = createAsyncThunk(
  "managers/checkFollowManagerStatus",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { user, id } = payload;
      console.log(id);
      let isFind = null;
      isFind = user.managersIds.find((i) => i == id);
      console.log(isFind);
      isFind == null ? dispatch(setFollow(false)) : dispatch(setFollow(true));
    } catch (error) {
      console.log(error);
    }
  }
);

export const unFollowManager = createAsyncThunk(
  "managers/unFollowManager",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      // console.log(payload);
      const { user, managersId } = payload;
      let newUserData = [];
      user.managersIds.map((id) => {
        if (id !== managersId) {
          newUserData.push(id);
        }
      });
      console.log(newUserData);
      await firebaseService.updateDocument(USERS, user.currentUserId, {
        managersIds: newUserData,
      });
      dispatch(openSuccessMessage("Unfollowed Successfully!"));
      dispatch(setFollow(false));

      // console.log(user.currentUserId);
    } catch (error) {
      console.log(error);
      dispatch(openErrorMessage("Sorry Something went wrong"));
    }
  }
);
export const followManager = createAsyncThunk(
  "managers/followManager",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { user, managersId } = payload;

      user.managersIds.push(managersId);

      console.log(user.managersIds);

      await firebaseService.updateDocument(USERS, user.currentUserId, {
        managersIds: user.managersIds,
      });
      dispatch(openSuccessMessage("Successfully Followed !"));

      dispatch(setFollow(true));
    } catch (error) {
      console.log(error);
      dispatch(openErrorMessage("Sorry Something went wrong"));
    }
  }
);
