import React, { useRef, useState } from "react";
import "./signUpForm.css";
import InputField from "../../common/InputField";
import SignUpFormImg from "../../../assets/images/bg22.png";
import { BsQuestionLg } from "react-icons/bs";
import { Formik } from "formik";
import * as Yup from "yup";
import Error from "../../common/Error";
import { useNavigate } from "react-router-dom";

import { HiOutlineUserCircle } from "react-icons/hi";
import { FaEdit } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { signup } from "../../../redux/thunks/users";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter your name"),
  mobileNumber: Yup.string().required("Please enter your mobile number"),
  email: Yup.string().required("Please enter your email"),
  password: Yup.string().required("Please enter your password"),
  address: Yup.string().required("Please enter your address"),
  dateOfBirth: Yup.string().required("Please enter your date of birth"),
  termsAndConditions: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
  passwordRepeat: Yup.string()
    .required("Please repeat your password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  avatar: Yup.mixed().required("Profile Photo is required"),
});

const SignUpForm = () => {
  const [avatar, setAvatar] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.users);

  const fileRef = useRef(null);

  return (
    <div>
      <div className="signup-form-page">
        <div className="signup-form-wrapper">
          <div className="signup-form">
            <h2>CREATE ACCOUNT</h2>
            <Formik
              initialValues={{
                email: "",
                password: "",
                name: "",
                mobileNumber: "",
                address: "",
                dateOfBirth: "",
                passwordRepeat: "",
                termsAndConditions: "",
                avatar: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                values.avatar = avatar;
                console.log(values);
                dispatch(signup({ navigate, data: values }));
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                touched,
                errors,
              }) => {
                return (
                  <div>
                    <div
                      onClick={() => {
                        fileRef.current.click();
                      }}
                    >
                      {avatar ? (
                        <div className="upload-avatar bg-white overflow-hidden mx-auto mt-4 mb-1 ">
                          <img
                            className="avatar-img"
                            src={URL.createObjectURL(avatar)}
                            alt=""
                            width="85px"
                          />
                        </div>
                      ) : (
                        <div className="upload-avatar mx-auto mt-4 mb-1 ">
                          <HiOutlineUserCircle className="user-icon" />
                          <FaEdit className="edit-icon" />
                        </div>
                      )}
                      {errors.avatar && touched.avatar && (
                        <Error text={errors.avatar} />
                      )}
                    </div>
                    <input
                      type="file"
                      ref={fileRef}
                      hidden
                      onChange={(e) => {
                        setFieldValue("avatar", e.target.files[0]);
                        setAvatar(e.target.files[0]);
                      }}
                    />
                    <InputField
                      placeholder="Name"
                      className="signup-inputs"
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <Error text={errors.name} />
                    )}
                    <InputField
                      placeholder="Date of Birth"
                      className="signup-inputs"
                      type="date"
                      name="dateOfBirth"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.dateOfBirth}
                    />
                    {errors.dateOfBirth && touched.dateOfBirth && (
                      <Error text={errors.dateOfBirth} />
                    )}
                    <InputField
                      placeholder="Your Email"
                      className="signup-inputs"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <Error text={errors.email} />
                    )}
                    <InputField
                      placeholder="Password"
                      className="signup-inputs"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {errors.password && touched.password && (
                      <Error text={errors.password} />
                    )}
                    <InputField
                      placeholder="Repeat your password"
                      className="signup-inputs"
                      type="password"
                      name="passwordRepeat"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.passwordRepeat}
                    />
                    {errors.passwordRepeat && touched.passwordRepeat && (
                      <Error text={errors.passwordRepeat} />
                    )}
                    <InputField
                      placeholder="Mobile Number"
                      className="signup-inputs"
                      type="text"
                      name="mobileNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobileNumber}
                    />
                    {errors.mobileNumber && touched.mobileNumber && (
                      <Error text={errors.mobileNumber} />
                    )}
                    <InputField
                      placeholder="City"
                      className="signup-inputs"
                      type="text"
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                    />
                    {errors.address && touched.address && (
                      <Error text={errors.address} />
                    )}
                    <div className="checkbox-input">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="termsAndConditions"
                        value={values.termsAndConditions}
                        onChange={handleChange}
                        id="flexCheckDefault"
                      />
                      <label>I agree with all Terms & Conditions</label>
                      <br />
                    </div>
                    <div className="ms-5">
                      {errors.termsAndConditions &&
                        touched.termsAndConditions && (
                          <Error text={errors.termsAndConditions} />
                        )}
                    </div>
                    {error ? (
                      <div class="alert alert-danger my-3" role="alert">
                        {error}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="signup-btn">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                      {/* {loading ? (
                        <button type="submit" disabled>
                          Loading...
                        </button>
                      ) : (
                        <button type="submit" onClick={handleSubmit}>
                          Submit
                        </button>
                      )} */}
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>

        <div className="signup-form-img">
          <img src={SignUpFormImg} />
        </div>
      </div>
      <div className="d-flex justify-content-end px-5 py-3">
        <div className="help d-flex align-items-center gap-2">
          <p className="mb-0">Help</p>
          <div className="question-mark">
            <BsQuestionLg />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
