import React from "react";
import "../about/about.css";
import Loader from "../../components/common/Loader";

const Contact = () => {
  return (
    <div>
      <Loader />
    </div>
  );
};

export default Contact;
