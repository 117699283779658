import { createAsyncThunk } from "@reduxjs/toolkit";
import firebaseService from "../../services/firebaseServices";
import { auth } from "../../config/firebaseConfig";
import { COLLECTIONS_NAMES } from "../../constants/firebase.constants.js";
import { storage } from "../../config/firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getUser } from "./users";
import { setEditModal } from "../slices/profileSlice";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";

const { EVENTS, TICKETS, USERS, CALLAPPCOL, MANAGERS } = COLLECTIONS_NAMES;

export const updateProfile = createAsyncThunk(
  "profile,updateProfile",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      console.log(payload);
      const { data, navigate, user } = payload;

      let avatarURL = "";

      // const user = JSON.parse(localStorage.getItem("authUser"));
      if (typeof data.avatar !== "string") {
        const imageRef = ref(storage, `${data.avatar.lastModified}`);
        const res = await uploadBytes(imageRef, data.avatar);
        avatarURL = await getDownloadURL(res.ref);
      }
      //   const userData = {
      //     name: data.name,
      //     mobileNumber: data.mobileNumber,
      //     address: data.address,
      //     avatar: avatarURL,
      //   };
      const response = await firebaseService.updateDocument(
        USERS,
        user.currentUserId,
        {
          name: data.name,
          mobileNumber: data.mobileNumber,
          address: data.address,
          avatar: avatarURL || data.avatar,
        }
      );
      // dispatch(getUser(user.currentUserId));
      //   navigate("/");
      dispatch(openSuccessMessage("Profile updated Successfully"));
      dispatch(setEditModal(false));

      //   console.log(response);
    } catch (error) {
      console.log(error);
      dispatch(openErrorMessage("Sorry! Something went wrong"));
      return rejectWithValue(error);
    }
  }
);
