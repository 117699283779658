import React, { useEffect, useState } from "react";

import "./login.css";
import { BsFillPersonFill } from "react-icons/bs";
import LogoImg from "../../../assets/images/logo-light.png";
import { Formik } from "formik";
import * as Yup from "yup";
import Error from "../../../components/common/Error";
import {
  forgetPassword,
  googleLogin,
  googleSignUp,
  login,
} from "../../../redux/thunks/users";
import { setGoogleModal } from "../../../redux/slices/userSlice";
import { GoogleSchema } from "../../../utils/Schemas";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FcGoogle } from "react-icons/fc";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useFirebaseUser from "../../../services/hooks/useFirebaseUser";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Please enter your email"),
  password: Yup.string().required("Please enter your password"),
});

const Login = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [forgetEmail, setForgetEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loading,
    googleLoading,
    error,
    googleModal,
    success,
    forgetPasswordError,
    user,
  } = useSelector((state) => state.users);
  // const { user } = useFirebaseUser();
  console.log(user);

  useEffect(() => {
    success && toast.success(success);
    forgetPasswordError && toast.error(forgetPasswordError);
  }, [success, forgetPasswordError]);

  const toggle = () => dispatch(setGoogleModal(!googleModal));
  const forgetModal = () => setIsOpen(!isOpen);

  return (
    <div className="login">
      <div className="login-wrapper"></div>
      <div className="login-header d-flex align-items-center justify-content-end">
        <img
          className="login-igp-logo"
          src={LogoImg}
          alt="logo"
          onClick={() => navigate("/home")}
        />
        <div className="mx-3">
          <div
            className="signup-nav-account"
            onClick={() => navigate("/sign-up")}
          >
            <div className="person-icon-wrapper">
              <BsFillPersonFill className="person-icon" />
            </div>
            <p>Sign Up</p>
          </div>
        </div>
      </div>
      <div className="login-content">
        <div className="login-content-section">
          <div>
            <img className="logoImg" src={LogoImg} alt="logo" />
            <h3>LOG IN</h3>
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              resetForm();
              dispatch(login({ navigate, ...values }));
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
            }) => {
              return (
                <form className="login-form">
                  <input
                    type="text"
                    name="email"
                    className="input-field login-inputs"
                    placeholder="Email Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <Error text={errors.email} />
                  )}
                  <input
                    type="password"
                    name="password"
                    className="input-field login-inputs"
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  {errors.password && touched.password && (
                    <Error text={errors.password} />
                  )}
                  {error ? (
                    <div class="alert alert-danger my-3" role="alert">
                      {error}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="login-btn">
                    {loading ? (
                      <button disabled>Loading...</button>
                    ) : (
                      <button type="submit" onClick={handleSubmit}>
                        Login
                      </button>
                    )}
                  </div>
                </form>
              );
            }}
          </Formik>
          <div className="mt-3">
            <p className="text-center">or Login with</p>
            <div
              className="google-login mx-auto"
              onClick={() => dispatch(googleLogin({ navigate }))}
            >
              <FcGoogle className="google-icon " />
            </div>
          </div>
          <div className="login-forget-password">
            <p>
              Have you forgotten your password? <br /> Click{" "}
              <span onClick={() => setIsOpen(true)}>here</span>
            </p>
          </div>
          <div className="login-footer ">
            {/* <Link to="/about-us">
              <p id="about">About us</p>
            </Link>
            <Link to="/contact-us">
              <p id="contact">Contact us</p>
            </Link> */}
            <a href="https://web.igotplans.co.uk" target="_blank">
              <p id="about">About us</p>
            </a>
            <a href="https://web.igotplans.co.uk" target="_blank">
              <p id="contact">Contact us</p>
            </a>
            <a href="https://manage.igotplans.co.uk/signup" target="_blank">
              <p id="contact">Become Manager</p>
            </a>
            <div className="d-flex flex-column gap-1">
              <a
                href="https://play.google.com/store/apps/details?id=com.artclick.igotplan&hl=en&gl=US"
                target="_blank"
              >
                <p
                  className="mb-0 "
                  style={{ fontSize: "12px", marginTop: "-5px" }}
                >
                  {" "}
                  Download App for Android{" "}
                </p>
              </a>
              <a
                href="https://apps.apple.com/pk/app/igotplans/id1663434251"
                target="_blank"
              >
                <p className="mb-0" style={{ fontSize: "12px" }}>
                  {" "}
                  Download App for Ios{" "}
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={googleModal} toggle={toggle}>
        <ModalBody>
          <Formik
            initialValues={{
              mobileNumber: "",
              dateOfBirth: "",
              address: "",
            }}
            validationSchema={GoogleSchema}
            onSubmit={(values, { resetForm }) => {
              dispatch(googleSignUp({ navigate, data: { ...values, user } }));
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
            }) => {
              return (
                <form className="login-form" onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="mobileNumber"
                    className="input-field login-inputs"
                    placeholder="Mobile Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mobileNumber}
                  />
                  {errors.mobileNumber && touched.mobileNumber && (
                    <Error text={errors.mobileNumber} />
                  )}
                  <input
                    type="text"
                    name="address"
                    className="input-field login-inputs"
                    placeholder="address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                  />
                  {errors.address && touched.address && (
                    <Error text={errors.address} />
                  )}
                  <input
                    type="date"
                    name="dateOfBirth"
                    className="input-field login-inputs"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.dateOfBirth}
                  />
                  {errors.dateOfBirth && touched.dateOfBirth && (
                    <Error text={errors.dateOfBirth} />
                  )}
                  {error ? (
                    <div class="alert alert-danger my-3" role="alert">
                      {error}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="login-btn">
                    <button
                      className="google-btn"
                      disabled={googleLoading}
                      type="submit"
                    >
                      Login
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>

      <Modal isOpen={isOpen} toggle={forgetModal}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(forgetPassword(forgetEmail));
            console.log("forgetPassword", forgetEmail);
          }}
        >
          <ModalHeader toggle={forgetModal}>
            <p className="text-center">Forget Password ?</p>
          </ModalHeader>
          <ModalBody>
            <div>
              <p className="text-center my-3">
                Please Enter your Registered Email
              </p>
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                className="input-field login-inputs my-4"
                value={forgetEmail}
                onChange={(e) => setForgetEmail(e.target.value)}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="submit"
              className="forget-send-btn"
              onClick={() => {
                forgetModal();
              }}
            >
              Send
            </button>{" "}
            <Button color="secondary" onClick={forgetModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Login;
