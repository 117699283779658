import React, { useEffect } from "react";
import HomeNav from "../../../components/home/HomeNav";
import "./previous.css";
import { Row, Col } from "reactstrap";
import { RiHeartLine, RiHeartFill } from "react-icons/ri";
import { FaTicketAlt } from "react-icons/fa";
import NoData from "../../utility/NoData";
// redux and friebase
import { useSelector, useDispatch } from "react-redux";
import { getPreviousEvents } from "../../../redux/thunks/events";
import Loader from "../../../components/common/Loader";
import Calender from "../../calendar/index";
import { getDateString } from "../../utility/getDateString";
import useFirebaseUser from "../../../services/hooks/useFirebaseUser";

const Previous = () => {
  const dispatch = useDispatch();
  const { prevEvents, loading, error } = useSelector((state) => state.events);
  // const user = JSON.parse(localStorage.getItem("authUser"));
  const { user } = useFirebaseUser();

  useEffect(() => {
    user && dispatch(getPreviousEvents(user?.currentUserId));
  }, [user]);

  return (
    <div className="previous-events">
      <HomeNav />
      {loading ? <Loader /> : ""}
      <div className="previous-content  mt-5 pt-5 pt-lg-5">
        <h2 className="previous-title mb-3">My Previous Events</h2>
        <div className="prev-scroll">
          {prevEvents?.length > 0 && !loading ? (
            <>
              {prevEvents?.map((event, index) => {
                const FavEvents = user?.favorite || [];
                const isFind = FavEvents.find((fav) => fav == event?.id);
                return (
                  <Row key={index}>
                    <Col sm={12} md={6} className="">
                      <div>
                        <Row className="mb-5">
                          <Col sm={12} md={4}>
                            <img
                              src={event?.eventData?.attachments?.[0]}
                              className="previous-event-img"
                            />
                          </Col>
                          <Col sm={12} md={8}>
                            <div className="d-flex justify-content-between mt-4 mt-md-0">
                              <div>
                                <h5 className="previous-card-title">
                                  {event?.eventData?.title}
                                </h5>
                                <p>{event?.eventData?.eventLocation}</p>
                              </div>
                              {isFind ? (
                                <RiHeartFill className="fav-icon fav-red-icon prev-fav-icon" />
                              ) : (
                                <RiHeartLine className="fav-icon prev-fav-icon" />
                              )}
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-3">
                              <div>
                                <p className="prev-event-date">
                                  Attended on the{" "}
                                  {getDateString(event?.eventData?.eventDate)}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <FaTicketAlt
                                  className="prev-ticket-icon"
                                  size={28}
                                />

                                <p className="prev-ticket-curr mb-0 ms-2">
                                  {" "}
                                  &#163; {event?.ticketData?.amountPaid}
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col sm={12} md={6} className="">
                      <div className="me-md-5"></div>
                    </Col>
                  </Row>
                );
              })}
            </>
          ) : prevEvents?.length == 0 && !loading ? (
            <NoData />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Previous;
