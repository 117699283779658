import React, { useState, useEffect } from "react";
import "./ticket.css";
import HomeNav from "../../components/home/HomeNav";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import {} from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  addFavouriteEvent,
  getEventDetails,
  removeFavouriteEvent,
} from "../../redux/thunks/events";
import { RiHeartLine, RiHeartFill } from "react-icons/ri";
import useFirebaseUser from "../../services/hooks/useFirebaseUser";

const Tickets = () => {
  const [isFav, setIsFav] = useState("");
  const dispatch = useDispatch();
  const [selectedTicket, setSelectedTicket] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  // const location = useLocation();
  // const event = location.state.event;
  // const { user } = useSelector((state) => state.users);
  const { user } = useFirebaseUser();
  const { favEvents, eventDetails } = useSelector((state) => state.events);

  const onChangeHandler = (index) => {
    setSelectedTicket(index);
    setIsSelected(true);
  };

  useEffect(() => {
    dispatch(getEventDetails({ eventId: params.id }));
  }, []);

  useEffect(() => {
    // const favEvents = user.favorite || [];
    const isFind = favEvents?.find((fav) => fav == eventDetails?.id);
    if (isFind) {
      setIsFav(true);
    } else {
      setIsFav(false);
    }
  }, [favEvents]);

  const addToFavHandler = (event) => {
    dispatch(
      addFavouriteEvent({
        user,
        eventId: event.id,
      })
    );
  };

  const removeToFavHandler = (event) => {
    dispatch(
      removeFavouriteEvent({
        user,
        favEvents,
        eventId: event.id,
      })
    );
  };

  console.log(params);

  return (
    <div>
      <HomeNav searchbar={false} />
      <div className="home-section">
        <div className="home-bg-wrapper"></div>
        <div className=" event-details-container">
          <div className="ticket-page">
            <div className="ticket-page-head">
              <div>
                <h4>{eventDetails?.title}</h4>
                <p>{eventDetails?.eventLocation}</p>
              </div>
              {isFav ? (
                <RiHeartFill
                  className="fav-icon fav-red-icon"
                  onClick={() => removeToFavHandler(eventDetails)}
                />
              ) : (
                <RiHeartLine
                  className="fav-icon"
                  onClick={() => addToFavHandler(eventDetails)}
                />
              )}
            </div>

            <div className="ticket-box">
              <div className="ticket-img">
                <img src={eventDetails?.attachments?.[0]} />
              </div>
              <form className="ticket-content">
                {eventDetails?.packages?.map((pkg, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <input
                        class="form-check-input mx-sm-3"
                        style={{ marginRight: "10px" }}
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        checked={selectedTicket === index}
                        onChange={(e) => onChangeHandler(index)}
                      />
                      <label className="ticket-pkg-label">{pkg?.detail}</label>
                    </div>

                    <p className="ticket-price">£ {pkg?.price}</p>
                  </div>
                ))}
                <div className="d-flex justify-content-end">
                  {isSelected ? (
                    <Link
                      to={`/payment/${eventDetails?.id}`}
                      state={{
                        event: eventDetails,
                        ticket: eventDetails?.packages?.[selectedTicket],
                      }}
                    >
                      <button className="ticket-btn">Buy Tickets</button>
                    </Link>
                  ) : (
                    <button className="ticket-btn" disabled={!selectedTicket}>
                      Buy Tickets
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tickets;
