/* eslint-disable import/no-anonymous-default-export */
export default {
  events: "/events",
  favourite: "/favourite",
  previousEvents: "/previous-events",
  upcomingEvents: "/upcoming-events",
  myTickets: "my-tickets",
  calender: "calender",
  settings: "/settings",
  wallet: "/wallet",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  signIn: "/login",
  signUp: "/sign-up",
  forgetPassword: "/forget-password",
  payment: "/payment/:eventId",
  tickets: "/event-details/:id/tickets",
  managerProfile: "/favourite/:id/manager",
  managerPublicProfile: "/managers/:id/profile",
  myProfile: "/profile/:id",
  eventDetails: "/event-details/:id",
  feedback: "/feedback",
  home: "/home",
  default: "*",
  // error: "*",
};
