import React, { useEffect } from "react";

import "./managerProfile.css";
import EventCards from "../../../components/common/EventCards";
import HomeNav from "../../../components/home/HomeNav";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  unFollowManager,
  followManager,
  getManagerEvents,
  getManagerFollowersCount,
  checkFollowManagerStatus,
  getFavOrganisers,
  getFavManager,
} from "../../../redux/thunks/managers";
import useFirebaseUser from "../../../services/hooks/useFirebaseUser";

const ManagerProfile = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useFirebaseUser();
  const { favManager, eventsCount, managerEvents, followersCount, follow } =
    useSelector((state) => state.managers);

  useEffect(() => {
    dispatch(getFavManager({ id: params.id }));
    dispatch(getManagerEvents({ id: params.id }));
    dispatch(getManagerFollowersCount(params.id));
    user && dispatch(checkFollowManagerStatus({ user, id: params.id }));
  }, []);

  return (
    <div>
      <HomeNav />
      <div className="container manager-profile-page">
        <div className="d-flex flex-column align-items-center justify-content-center gap-3 mx-auto">
          <div>
            <div className="mx-auto manager-detail-avatar">
              <img src={favManager?.img} />
            </div>
            <h4 className="mt-2 text-center manager-heading">
              {favManager?.name}
            </h4>
            <p className="mt-2 mb-4 text-center">{favManager?.bio}</p>
          </div>
          <div className="d-flex flex-wrap gap-3 w-100">
            <div className="white-box">
              <h5 className="manager-heading">Events</h5>
              <p>{managerEvents?.length}</p>
            </div>
            <div className="white-box">
              <h5 className="manager-heading">Followers</h5>
              <p>{followersCount}</p>
            </div>
            <div className="white-box">
              <h5 className="manager-heading">Experience</h5>
              <p>
                {favManager?.experience?.years > 0
                  ? `${favManager?.experience?.years} yr ${favManager?.experience?.months} months`
                  : `${favManager?.experience?.months || 0} months`}
              </p>
            </div>
          </div>
          <div className="mx-auto">
            {user?.managersIds?.includes(favManager.id) ? (
              <button
                className="follow-btn"
                onClick={() =>
                  dispatch(
                    unFollowManager({ managersId: favManager?.id, user })
                  )
                }
              >
                Unfollow
              </button>
            ) : !user?.managersIds?.includes(favManager.id) ? (
              <button
                className="follow-btn"
                onClick={() =>
                  dispatch(followManager({ managersId: favManager.id, user }))
                }
              >
                Follow
              </button>
            ) : null}
          </div>
          <div className="w-100 mt-4">
            {managerEvents?.map((event) => {
              return <EventCards event={event} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerProfile;
