import { createSlice } from "@reduxjs/toolkit";
import {
  chargeByWallet,
  chargeCustomerByPaymentMethod,
  createPaymentMethod,
  deletePaymentCard,
  getCustomerCardsList,
} from "../thunks/payment";

const initialState = {
  loading: false,
  error: null,
  success: null,
  cards: [],
  formNo: 1,
};

const handlePending = (state, action) => {
  state.loading = true;
  state.error = initialState.error;
  state.success = initialState.success;
};
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};
const handleSuccess = (state, action) => {
  state.loading = false;
  state.success = action.payload;
};
const onGetCardsList = (state, action) => {
  state.loading = false;
  state.cards = action.payload;
};
const onCreatePaymentmethod = (state, action) => {
  state.loading = false;
  state.cards = [...state.cards, action.payload];
  state.success = "Card Added Successfully";
};
const onDeleteCard = (state, action) => {
  state.loading = false;
  state.cards = state.cards.filter((card) => card.id !== action.payload.id);
  state.success = action.payload.message;
};
const onChargeCustomer = (state, action) => {
  state.loading = false;
  state.success = "Payment Successful";
};
const onChargeByWallet = (state, action) => {
  state.loading = false;
};

const slice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setFormNo: (state, action) => {
      state.formNo = action.payload;
    },
    clearAlerts: (state) => {
      state.error = "";
      state.success = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerCardsList.pending, handlePending)
      .addCase(getCustomerCardsList.fulfilled, onGetCardsList)
      .addCase(getCustomerCardsList.rejected, handleRejected)

      .addCase(createPaymentMethod.pending, handlePending)
      .addCase(createPaymentMethod.fulfilled, onCreatePaymentmethod)
      .addCase(createPaymentMethod.rejected, handleRejected)

      .addCase(deletePaymentCard.pending, handlePending)
      .addCase(deletePaymentCard.fulfilled, onDeleteCard)
      .addCase(deletePaymentCard.rejected, handleRejected)

      .addCase(chargeByWallet.pending, handlePending)
      .addCase(chargeByWallet.fulfilled, onChargeByWallet)
      .addCase(chargeByWallet.rejected, handleRejected)

      .addCase(chargeCustomerByPaymentMethod.pending, handlePending)
      .addCase(chargeCustomerByPaymentMethod.fulfilled, onChargeCustomer)
      .addCase(chargeCustomerByPaymentMethod.rejected, handleRejected);
  },
});

export const { setFormNo, clearAlerts } = slice.actions;
export default slice.reducer;
