import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import {
  openErrorMessage,
  openSuccessMessage,
} from "../../../redux/slices/toastSlice";

const Tosat = () => {
  const { success, error } = useSelector((state) => state.toast);
  const dispatch = useDispatch();

  useEffect(() => {
    success && toast.success(success);
    error && toast.error(error);
    setTimeout(() => {
      dispatch(openErrorMessage(""));
      dispatch(openSuccessMessage(""));
    }, 3000);
  }, [success, error]);

  return (
    <div>
      <ToastContainer autoClose={3000} theme="dark" />
    </div>
  );
};

export default Tosat;
