import Login from "../pages/authentication/login";
import SignUp from "../pages/authentication/signup";
import Calender from "../pages/calendar";
import FavouriteEvents from "../pages/events/Favourite";
import Previous from "../pages/events/Previous";
import UpcomingEvents from "../pages/events/UpcomingEvents";
import MyTickets from "../pages/tickets/MyTickets";
import Wallet from "../pages/wallet";
import Contact from "../pages/contact";
import About from "../pages/about";
import paths from "../utils/paths";
import Payment from "../pages/payment";
import ManagerProfile from "../pages/profile/managerProfile";
import MyProfile from "../pages/profile/myProfile";
import EventDetails from "../pages/events/EventDetails";
import Settings from "../pages/settings";
import Page404 from "../pages/utility/Page404";
import Home from "../pages/home";
import Tickets from "../pages/tickets";
import Feedback from "../pages/feedback";

export const PublicRoutes = [
  { path: paths.signIn, component: <Login /> },
  { path: paths.signUp, component: <SignUp /> },
  { path: paths.default, component: <Home /> },
  { path: paths.aboutUs, component: <About /> },
  { path: paths.contactUs, component: <Contact /> },
  { path: paths.error, component: <Page404 /> },
  { path: paths.eventDetails, component: <EventDetails /> },
  { path: paths.home, component: <Home /> },
];

export const PrivateRoutes = [
  { path: paths.favourite, component: <FavouriteEvents /> },
  { path: paths.previousEvents, component: <Previous /> },
  { path: paths.upcomingEvents, component: <UpcomingEvents /> },
  { path: paths.myTickets, component: <MyTickets /> },
  { path: paths.calender, component: <Calender /> },
  { path: paths.settings, component: <Settings /> },
  { path: paths.wallet, component: <Wallet /> },
  { path: paths.payment, component: <Payment /> },
  { path: paths.tickets, component: <Tickets /> },
  { path: paths.managerProfile, component: <ManagerProfile /> },
  { path: paths.managerPublicProfile, component: <ManagerProfile /> },
  { path: paths.myProfile, component: <MyProfile /> },
  { path: paths.eventDetails, component: <EventDetails /> },
  { path: paths.feedback, component: <Feedback /> },
  { path: paths.home, component: <Home /> },
  { path: paths.default, component: <Home /> },
];
// export const commonRoutes = [
//   { path: paths.eventDetails, component: <EventDetails /> },
//   { path: paths.home, component: <Home /> },
// ];

// payment: "/payment/:eventId",
// tickets: "/event-details/:id/tickets",
// managerProfile: "/favourite/:id/manager",
// myProfile: "/profile/:id",
// eventDetails: "/event-details/:id",
