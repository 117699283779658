import React, { useEffect, useState } from "react";

import Marker from "../../common/Marker";
import PinLocation from "../../../assets/icons/pin.png";

import Geocode from "react-geocode";
import GoogleMapReact from "google-map-react";

const EventMap = ({ location }) => {
  const [eventLocation, setEventLocation] = useState({});

  useEffect(() => {
    Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
    Geocode.fromAddress(location).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setEventLocation({
          center: {
            lat: lat,
            lng: lng,
          },
          zoom: 10,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);

  return (
    <div
      className="mt-5"
      style={{
        width: "100%",
        height: "300px",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <GoogleMapReact
        center={eventLocation?.center}
        zoom={eventLocation?.zoom}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_MAP_KEY,
        }}
      >
        <Marker
          lat={eventLocation?.center?.lat}
          lng={eventLocation?.center?.lng}
          text="My Marker"
          img={PinLocation}
          //   width="30px"
          //   height="30px"
        />
      </GoogleMapReact>
    </div>
  );
};

export default EventMap;
