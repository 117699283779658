import { createAsyncThunk } from "@reduxjs/toolkit";
import firebaseService from "../../services/firebaseServices";
import { COLLECTIONS_NAMES } from "../../constants/firebase.constants.js";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";

const { USERS, CALLAPPCOL, TICKETS, EVENTS } = COLLECTIONS_NAMES;

export const buyTicket = createAsyncThunk(
  "tickets/buyTicket",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { userId, event, ticket, navigate } = data;
      const ticketData = {
        amountPaid: ticket.price,
        eventDate: event.eventDate,
        eventId: event.id,
        feedbackNotes: null,
        isCheckedIn: null,
        isPaid: false,
        paymentMethod: null,
        purchaseDate: new Date().toISOString(),
        ratingByEvent: null,
        ratingByUser: null,
        ticketType: ticket.detail,
        userId: userId,
      };
      const eventData = {
        EndTime: event.eventDate,
        StartTime: event.eventDate,
        Subject: event.title,
        duration: event.requiredTime,
        eventId: event.id,
        eventName: event.title,
        imgUrl: event.attachments?.[0],
        startingTime: event.startTime,
      };
      await firebaseService.addDocument(TICKETS, ticketData);
      await firebaseService.addDocumentWithNestedCollection(
        USERS,
        CALLAPPCOL,
        userId,
        eventData
      );
      dispatch(openSuccessMessage("Ticket purchased Successfully"));
      navigate("/upcoming-events");
    } catch (err) {
      console.log(err);
      dispatch(openErrorMessage("Error while buying Ticket"));
      return rejectWithValue(err.message);
    }
  }
);

export const getMyTicket = createAsyncThunk(
  "tickets/getMyTickets",
  async (id, { rejectWithValue }) => {
    try {
      console.log("userId", id);
      const upcomingEvents = await firebaseService.queryDocuments(
        TICKETS,
        "userId",
        id
      );
      const events = await Promise.all(
        upcomingEvents?.map(async (event) => {
          const e = await firebaseService.getDocumentById(
            EVENTS,
            event.eventId
          );
          return { eventData: e, ticketData: event };
        })
      );

      // const filteredEvents = events.filter(
      //   (event) => new Date(event.eventData.eventDate) < new Date()
      // );
      const sortedEvents = events?.sort((a, b) => {
        const dateA = new Date(a.eventData.eventDate);
        const dateB = new Date(b.eventData.eventDate);
        return dateA - dateB;
      });
      console.warn(sortedEvents);
      return sortedEvents;

      // return events;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);
