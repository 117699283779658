import React, { useState, useEffect } from "react";
import "./calenderView.css";
import {
  startOfMonth,
  endOfMonth,
  differenceInDays,
  sub,
  add,
  format,
  setDate,
  parse,
  isEqual,
  startOfDay,
} from "date-fns";
import { BsFillCircleFill } from "react-icons/bs";
import { useNavigate, Link } from "react-router-dom";
import CalenderEvent from "./calenderEvent/CalenderEvent";

const colors = ["#F5D41F", "#EF6E48", "#1FD4F5"];

const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  const randomElement = colors[randomIndex];
  return randomElement;
};

const Cell = ({
  style,
  children,
  onClick,
  isActive,
  ponits = [],
  onClickMarkHandler,
}) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        ...style,
        height: "60px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: "0.5px solid black",
        borderRight: "0.5px solid black",
        backgroundColor: isActive ? "#eb9481" : null,
      }}
      onClick={onClick}
    >
      <div className="d-flex flex-column">
        {children}
        <div className="d-flex justify-content-between">
          {ponits && ponits.length > 0
            ? ponits?.map((event, index) => (
                <>
                  <BsFillCircleFill
                    key={index}
                    size={10}
                    onClick={() => onClickMarkHandler(event)}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={event.eventName}
                    style={{
                      cursor: "pointer",
                      color: getRandomColor(),
                      marginBottom: "-20px",
                      marginLeft: "3px",
                    }}
                  />
                </>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

const CalenderView = ({ value, onChange, markers }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  const onClickMarkHandler = (event) => {
    setSelectedEvent(event?.eventData);
  };

  const daysOfWeek = ["M", "T", "W", "T", "F", "S", "S"];
  const days = [];

  const startDate = startOfMonth(value);
  const endDate = endOfMonth(value);

  const numDays = differenceInDays(endDate, startDate) + 1;
  const prefixDays = startDate.getDay() - 1;
  const sufixDays = 7 - endDate.getDay();

  const prevMonth = () => onChange(sub(value, { months: 1 }));
  const nextMonth = () => onChange(add(value, { months: 1 }));

  return (
    <>
      <div
        style={{
          width: "100%",
          minHeight: "100px",
          borderTop: "0.5px solid black",
          borderLeft: "0.5px solid black",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(7, minmax(0,1fr))",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Cell></Cell>
          <Cell onClick={prevMonth} style={{ cursor: "pointer" }}>
            {"<"}
          </Cell>
          <Cell style={{ gridColumn: "span 3 / span 3", fontSize: "24px" }}>
            {format(value, "LLLL yyyy")}
          </Cell>
          <Cell onClick={nextMonth} style={{ cursor: "pointer" }}>
            {">"}
          </Cell>
          <Cell></Cell>

          {daysOfWeek?.map((day, index) => {
            return (
              <Cell key={index} style={{ fontSize: 32, fontWeight: "bold" }}>
                {day}
              </Cell>
            );
          })}
          {days?.map((day, index) => {
            return (
              <Cell key={index} style={{ fontSize: 18 }}>
                {day}
              </Cell>
            );
          })}
          {Array.from({ length: prefixDays }).map((_, index) => {
            return <Cell key={index}></Cell>;
          })}
          {Array.from({ length: numDays }).map((_, index) => {
            const date = index + 1;
            const currentDate = startOfDay(setDate(value, date));
            const noOfEventsToday = [];
            markers?.forEach((maker) => {
              const markerDate = startOfDay(
                parse(maker.StartTime, "yyyy/M/d", new Date())
              );
              if (isEqual(currentDate, markerDate)) {
                noOfEventsToday.push(maker);
              }
            });
            return (
              <Cell
                key={date}
                ponits={noOfEventsToday}
                onClickMarkHandler={onClickMarkHandler}
              >
                {date}
              </Cell>
            );
          })}
          {Array.from({ length: sufixDays }).map((_, index) => {
            return <Cell key={index}></Cell>;
          })}
        </div>
      </div>
      {selectedEvent && <CalenderEvent event={selectedEvent} />}
    </>
  );
};

export default CalenderView;
