import React, { useEffect, useState } from "react";

import "./eventManager.css";
import { getEventManager } from "../../../redux/thunks/events";

import { useDispatch, useSelector } from "react-redux";
import {
  checkFollowManagerStatus,
  followManager,
  unFollowManager,
} from "../../../redux/thunks/managers";
import { setFollow } from "../../../redux/slices/managersSlice";
import useFirebaseUser from "../../../services/hooks/useFirebaseUser";
import { Link } from "react-router-dom";

const EventManager = ({ eventManager }) => {
  const dispatch = useDispatch();
  // const { eventManager } = useSelector((state) => state.events);
  const { follow, loading } = useSelector((state) => state.managers);
  const { user } = useFirebaseUser();
  // console.log(managersId);

  useEffect(() => {
    // dispatch(getEventManager({ managersId: eventManager.id }));
    let isFind = null;
    isFind = user?.managersIds.find((id) => id == eventManager.id);
    isFind == null ? dispatch(setFollow(false)) : dispatch(setFollow(true));
    // user &&
    // dispatch(checkFollowManagerStatus({ managersId: eventManager.id, user }));
  }, []);

  return (
    <div className="d-flex  justify-content-between align-items-center gap-2 flex-wrap mt-3 mb-lg-2 mt-lg-0">
      <Link to={`/managers/${eventManager.id}/profile`}>
        <div className="d-flex align-items-center gap-3 profile">
          <div className="manager-profile-img">
            <img src={eventManager?.img} />
          </div>
          <p className="m-0">{eventManager?.name}</p>
        </div>
      </Link>
      <div className="">
        {user?.managersIds?.includes(eventManager.id) ? (
          <button
            className="manager-follow-btn mt-0"
            onClick={() =>
              dispatch(unFollowManager({ managersId: eventManager.id, user }))
            }
            disabled={loading || user?.status !== "active"}
          >
            Unfollow
          </button>
        ) : !user?.managersIds?.includes(eventManager.id) ? (
          <button
            className="manager-follow-btn mt-0"
            onClick={() =>
              dispatch(followManager({ managersId: eventManager.id, user }))
            }
            disabled={loading || user?.status !== "active"}
          >
            Follow
          </button>
        ) : null}

        {/* {follow ? (
          <button
            className="manager-follow-btn mt-0"
            onClick={() =>
              dispatch(unFollowManager({ managersId: eventManager.id, user }))
            }
            disabled={loading}
          >
            Unfollow
          </button>
        ) : (
          <button
            className="manager-follow-btn mt-0"
            onClick={() =>
              dispatch(followManager({ managersId: eventManager.id, user }))
            }
            disabled={loading}
          >
            Follow
          </button>
        )} */}
      </div>
    </div>
  );
};

export default EventManager;
