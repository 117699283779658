import { createSlice } from "@reduxjs/toolkit";
import { buyTicket, getMyTicket } from "../thunks/tickets";

const initialState = {
  ticket: {},
  myTickets: [],
  loading: false,
  error: null,
  success: null,
};

const handlePending = (state, action) => {
  state.loading = true;
  state.error = initialState.error;
  state.success = initialState.success;
};
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};
const handleSuccess = (state, action) => {
  state.loading = false;
  state.success = action.payload;
};

const onBuyTicket = (state, action) => {
  state.ticket = action.payload;
  state.loading = false;
  state.success = true;
};

const onGetMyTicket = (state, action) => {
  state.myTickets = action.payload;
  state.loading = false;
  state.success = true;
};

const slice = createSlice({
  name: "tickets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(buyTicket.pending, handlePending)
      .addCase(buyTicket.fulfilled, onBuyTicket)
      .addCase(buyTicket.rejected, handleRejected)

      .addCase(getMyTicket.pending, handlePending)
      .addCase(getMyTicket.fulfilled, onGetMyTicket)
      .addCase(getMyTicket.rejected, handleRejected);
  },
});

export const {} = slice.actions;
export default slice.reducer;
