import React, { useEffect } from "react";

import "./myProfile.css";
import HomeNav from "../../../components/home/HomeNav";
import { getUser } from "../../../redux/thunks/users";
import EditProfileModal from "../../../components/profile/editProfileModal";

import { FaEdit } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setEditModal } from "../../../redux/slices/profileSlice";
import { useNavigate } from "react-router-dom";
import useFirebaseUser from "../../../services/hooks/useFirebaseUser";

const MyProfile = () => {
  // const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { user } = useSelector((state) => state.users);
  const { user } = useFirebaseUser();
  const { editModal, success, error } = useSelector((state) => state.profile);

  return (
    <div>
      <HomeNav />
      <div className="container manager-profile-page">
        <div className="d-flex flex-column align-items-center justify-content-center gap-3 mx-auto">
          <div className="d-flex justify-content-end w-100">
            <div
              className="d-flex align-items-center gap-2 edit-wrapper"
              onClick={() => navigate("/settings")}
            >
              <p className="mb-0">Edit</p>
              <FaEdit />
            </div>
          </div>
          <div>
            <div className="mx-auto position-relative profile-avatar-wrapper">
              <div className="mx-auto manager-detail-avatar">
                <img src={user?.avatar} />
              </div>
            </div>
            <h4 className="mt-2 text-center manager-heading">{user?.name}</h4>
            <p className="mt-2 mb-4 text-center">{user?.email}</p>
          </div>
          <div className="d-flex flex-wrap gap-3 w-100">
            <div className="white-box">
              <h5 className="manager-heading">Likes</h5>
              <p>{user?.favorite?.length}</p>
            </div>
            <div className="white-box">
              <h5 className="manager-heading">Attended</h5>
              <p>{user?.userLevel?.[0]}</p>
            </div>
            <div className="white-box">
              <h5 className="manager-heading">Following</h5>
              <p>{user?.managersIds?.length}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
