import React from "react";
import "./inputField.css";

const InputField = ({
  placeholder = "",
  type = "text",
  className,
  ...props
}) => (
  <input
    className={`input-field ${className}`}
    type={type}
    placeholder={placeholder}
    {...props}
  />
);

export default InputField;
