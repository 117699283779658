import React, { useEffect } from "react";
import HomeNav from "../../components/home/HomeNav";
import "./myTickets.css";
import { Row, Col } from "reactstrap";
import { RiHeartLine, RiHeartFill } from "react-icons/ri";
import { FaTicketAlt } from "react-icons/fa";
import NoData from "../utility/NoData";
// redux and friebase
import { useSelector, useDispatch } from "react-redux";
import { getMyTicket } from "../../redux/thunks/tickets";
import Loader from "../../components/common/Loader";
import { getDateAndTime } from "../utility/getDates";
import useFirebaseUser from "../../services/hooks/useFirebaseUser";

const MyTickets = () => {
  const dispatch = useDispatch();
  const { myTickets, loading, error } = useSelector((state) => state.tickets);
  // const user = JSON.parse(localStorage.getItem("authUser"));
  const { user } = useFirebaseUser();

  console.log("user", user);

  useEffect(() => {
    user && dispatch(getMyTicket(user?.currentUserId));
  }, [user]);

  return (
    <div className="upcoming-events">
      <HomeNav />
      {loading ? <Loader /> : ""}
      <div className="upcoming-content  mt-5 pt-5 pt-lg-5">
        <h2 className="upcoming-title mb-3">My Tickets</h2>
        <div className="upcoming-scroll">
          {myTickets?.length > 0 && !loading ? (
            <>
              {myTickets?.map((event, index) => {
                const FavEvents = user?.favorite || [];
                const isFind = FavEvents.find(
                  (fav) => fav == event?.eventData?.id
                );
                return (
                  <Row key={index}>
                    <Col sm={12} md={10} lg={8} className="">
                      <div>
                        <Row className="mb-5">
                          <Col sm={12} md={3}>
                            <img
                              src={event?.eventData?.attachments?.[0]}
                              className="upcoming-event-img my-tickets-img"
                            />
                          </Col>
                          <Col sm={12} md={9}>
                            <div className="d-flex justify-content-between mt-4 mt-md-0">
                              <div>
                                <h5 className="upcoming-card-title">
                                  {event?.eventData?.title}
                                </h5>
                                <p>{event?.eventData?.eventLocation}</p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mt-2 mt-md-0">
                              <p className="upcoming-event-date">Ticket ID:</p>
                              <p>
                                {event?.ticketData?.ticketId.substring(0, 8)}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mt-2 mt-md-0">
                              <p className="upcoming-event-date">
                                Ticket Type:
                              </p>
                              <p>{event?.ticketData?.ticketType}</p>
                            </div>
                            <div className="d-flex justify-content-between mt-2 mt-md-0">
                              <p className="upcoming-event-date">
                                Ticket Purchase At:
                              </p>
                              <p>
                                {getDateAndTime(
                                  event?.ticketData?.purchaseDate
                                )}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <p className="upcoming-event-date">
                                  Ticket Price
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <FaTicketAlt
                                  className="upcoming-ticket-icon"
                                  size={28}
                                />
                                <p className="upcoming-ticket-curr mb-0 ms-2">
                                  {" "}
                                  &#163; {event?.ticketData?.amountPaid}
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </>
          ) : myTickets?.length == 0 && !loading ? (
            <NoData />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default MyTickets;
