import React from "react";

import "./signup.css";
import SignUpNav from "../../../components/signup/SignUpNav";
import SignUpForm from "../../../components/signup/SignUpForm";

const SignUp = () => {
  return (
    <div>
      <div className="signup-page">
       <SignUpNav />
       <SignUpForm />
      </div>
    </div>
    
  );
};

export default SignUp;
