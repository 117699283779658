import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Images
import noData from "../../assets/images/NoData.png";

const NoData = () => {
  return (
    <React.Fragment>
      <Container>
        <Row className="justify-content-center">
          <Col md="8" xl="6">
            <div>
              <img src={noData} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="text-center mb-2">
              <h3 className="text-capitalize fw-bold">No Data Available</h3>
              {/* <div className="mt-4 text-center">
                <Link className="btn" to="/dashboard" style={{backgroundColor:""}}>
                  Back to Dashboard
                </Link>
              </div> */}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default NoData;
