import React from "react";

const Marker = ({ img, height = "40px", width = "40px" }) => {
  return (
    <div className="marker" style={{ width, height }}>
      <img src={img} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default Marker;
