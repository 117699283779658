import { createAsyncThunk } from "@reduxjs/toolkit";
import firebaseService from "../../services/firebaseServices";
import { COLLECTIONS_NAMES } from "../../constants/firebase.constants.js";
import { auth } from "../../config/firebaseConfig";
import * as API from "../../services/api";
import { setGoogleModal, setUser } from "../slices/userSlice";
import { storage } from "../../config/firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { sendPasswordResetEmail } from "firebase/auth";
import { setFormNo } from "../slices/paymentSlice";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";
import { buyTicket } from "./tickets";
import { addToCalenderEvents } from "./events";

const { USERS, EVENTS, VENUES, MANAGERS, TICKETS } = COLLECTIONS_NAMES;

export const getCustomerCardsList = createAsyncThunk(
  "payment/getCustomerCardsList",
  async (payload, { rejectWithValue }) => {
    try {
      const { user } = payload;

      const cardsList = await API.getCustomerCardsList(user.customerId);
      console.log(cardsList);
      return cardsList;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const createPaymentMethod = createAsyncThunk(
  "payment/createPaymentMethod",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data, user } = payload;
      const { cardNumber, expiryMonth, expiryYear, cvc } = data;

      const card = await API.createPaymentMethod(
        cardNumber,
        expiryMonth,
        expiryYear,
        cvc
      );

      const response = await API.attachCard(card.id, user.customerId);
      console.log(response);
      dispatch(openSuccessMessage("Payment Card added "));
      dispatch(setFormNo(1));
      return response;
    } catch (error) {
      console.log(error);
      dispatch(
        openErrorMessage("Sorry Something went wrong... try again later")
      );
      return rejectWithValue(error);
    }
  }
);

export const deletePaymentCard = createAsyncThunk(
  "payment,deletePaymentCard",
  async (payload, { dispatch }) => {
    try {
      const response = await API.deleteCard(payload);
      console.log(response);
      dispatch(
        openSuccessMessage("Your payment method has been deleted successfully")
      );
      return {
        id: payload,
        message: `Your payment method has been deleted successfully`,
      };
    } catch (error) {
      console.log(error);
      dispatch(openErrorMessage("Sorry Something went wrong "));
    }
  }
);

export const chargeCustomerByPaymentMethod = createAsyncThunk(
  "payment,chargeCustomerByPaymentMethod",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      console.log(payload);
      const { ticket, event, user } = payload;
      const response = await API.createSessionUrl({
        event,
        customerId: user.customerId,
        userId: user.currentUserId,
        ticketPrice: ticket.price,
      });
      const sessionUrl = response.data.url;
      window.location.href = sessionUrl;
      return;
      // const response = await API.chargeCustomer(
      //   card.id,
      //   card.customer,
      //   ticket.price * 100
      // );
      // if (response.status == "succeeded") {
      //   dispatch(
      //     buyTicket({ userId: user.currentUserId, ticket, event, navigate })
      //   );
      // }
      // dispatch(openSuccessMessage("Transaction Successfull"));
      // console.log(response);
      // return response;
    } catch (error) {
      dispatch(
        openErrorMessage("Sorry Something went wrong ... try again later")
      );
      return rejectWithValue(error);
    }
  }
);

export const addWalletPoints = createAsyncThunk(
  "payment/addWalletPoints",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      console.log(payload);
      const { points, card, user } = payload;

      const response = await API.chargeCustomer(
        card.id,
        card.customer,
        (points * 100) / 2
      );
      console.log(response);
      if (response.status == "succeeded") {
        const res = await firebaseService.updateDocument(
          USERS,
          user.currentUserId,
          {
            pointsAmount: `${
              parseFloat(user.pointsAmount || 0) + parseFloat(points)
            }`,
          }
        );
        dispatch(openSuccessMessage("Points Added Successfully"));
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      dispatch(openErrorMessage("Something went wrong"));
    }
  }
);

export const chargeByWallet = createAsyncThunk(
  "payment/chargeByWallet",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { user, ticket, event, navigate } = payload;
      let ticketPrice = Number(ticket.price);
      let userBalance = Number(user.pointsAmount);
      if (userBalance < ticketPrice) {
        throw new Error("User need more points");
      }
      let newPoints = (userBalance - ticketPrice).toString();
      await firebaseService.updateDocument(USERS, user.currentUserId, {
        pointsAmount: newPoints,
      });
      dispatch(
        buyTicket({ userId: user.currentUserId, ticket, event, navigate })
      );
      dispatch(openSuccessMessage("Payment Successfull"));
    } catch (error) {
      dispatch(openErrorMessage(error.message || "Error Occured"));
    }
  }
);

export const chargeByCash = createAsyncThunk(
  "payment/chargeByCash",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { user, ticket, event, navigate } = payload;
      dispatch(
        buyTicket({ userId: user.currentUserId, ticket, event, navigate })
      );
      dispatch(openSuccessMessage("Payment Successfull"));
    } catch (error) {
      console.log(error);
      dispatch(openErrorMessage("Error Occured"));
    }
  }
);
