import { createAsyncThunk } from "@reduxjs/toolkit";
import firebaseService from "../../services/firebaseServices";
import { auth } from "../../config/firebaseConfig";
import { COLLECTIONS_NAMES } from "../../constants/firebase.constants.js";
// import { onSnapshot, doc } from "firebase/firestore";
// import { db } from "../../config/firebaseConfig";

const { NOTIFICATIONS, MANAGERS } = COLLECTIONS_NAMES;

export const getMyNotifications = createAsyncThunk(
  "notifications, getMyNotifications",
  async (payload, { rejectWithValue }) => {
    try {
      const { user } = payload;

      const response1 = await firebaseService.queryDocumentInArray(
        NOTIFICATIONS,
        "createdTo",
        user.currentUserId
      );
      const response2 = await firebaseService.queryDocs(
        NOTIFICATIONS,
        "createdTo",
        "==",
        user.currentUserId
      );
      const response = [...response1, ...response2];
      const res = await Promise.all(
        response?.map(async (managers) => {
          return await firebaseService.getDocumentById(
            MANAGERS,
            managers?.createdBy
          );
        })
      );
      const mergedArray = response.map((item, index) => {
        // Combine elements from both arrays into a new object
        const mergedObject = { ...item, manager: { ...res[index] } };
        return mergedObject;
      });
      return mergedArray;
    } catch (error) {}
  }
);
